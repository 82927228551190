import React, { useCallback, memo } from 'react';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined'
import { useHistory } from 'react-router-dom';

import { logout } from '../../domain/auth/action';
import { useDomain } from '../../utils/domain';
import { LogoutBox, LogoutText } from './style';

const SidebarLogout = memo(() => {
    const { dispatch } = useDomain(state => state.auth);
    const history = useHistory();
    const signOut = useCallback(() => {
        history.replace('/login');
        dispatch(logout());
    }, [dispatch, history]);

    return (
        <LogoutBox>
            <LogoutOutlined /> 
            <LogoutText onClick={signOut}>Sair da conta</LogoutText>
        </LogoutBox>
    );
});

export default SidebarLogout;