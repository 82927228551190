// Third party
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CommonProvider } from '../../domain/provider';
import theme from '../../theme';
import { routes } from './routes';

export default () => (
  <CommonProvider>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          {routes.map(route => (
            <Route key={route.path} path={route.path} exact={route.exact || typeof route.exact === 'undefined'} component={route.component} />
          ))}
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </CommonProvider>
);