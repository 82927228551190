import styled from "styled-components";

export const MenuButton = styled.button`
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;

    @media only screen and (min-width: 1025px) {
        display: none;
    }
`;

export const BrandHeader = styled.div`
    @media only screen and (min-width: 1025px) {
        display: none;
    }
`;