import React, { memo } from "react";
import { Row, Col, Grid } from "antd";
import { CarTwoTone, EyeTwoTone, LikeTwoTone } from "@ant-design/icons";
import Card from "../../../components/Card";
import Statistic from "../../../components/Statistic";

const { useBreakpoint } = Grid;

const DashboardCards = memo(({ data }) => {
    const screens = useBreakpoint();

    return (
        <Row gutter={screens.md ? 20 : 10}>
            <Col md={8} span={12} order={screens.md ? 1 : 2}>
                <Card marginBottom>
                    <Statistic 
                        title="Quantidade"
                        icon={<CarTwoTone />}
                        entries={[
                            ["Lojas", data.stores],
                            ["Anúncios", data.vehicles]
                        ]}
                    />
                </Card>
            </Col>
            <Col md={8} span={24} order={screens.md ? 2 : 1}>
                <Card marginBottom>
                    <Statistic 
                        title="Visualizações"
                        icon={<EyeTwoTone />}
                        entries={[
                            ["Página", data.pageViews]
                        ]}
                    />
                </Card>
            </Col>
            <Col md={8} span={12} order={3}>
                <Card marginBottom>
                    <Statistic 
                        title="Interesse"
                        icon={<LikeTwoTone />}
                        entries={[
                            ["Telefone", data.phoneViews],
                            ["WhatsApp", data.socialCalls]
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    );
});

export default DashboardCards;