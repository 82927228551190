import styled from "styled-components";

export const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Separator = styled.div`
    display: inline-block;
    height: 40px;
    width: 1px;
    background: #DDD;
    margin: 0 15px;
`;