import React, { createContext, useReducer } from 'react';
import initialState from './state';
import reducer from './reducer';

export const CommonCtx = createContext({});
export const CommonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CommonCtx.Provider value={{
      state,
      dispatch
    }}>
      {children}
    </CommonCtx.Provider>
  )
};
