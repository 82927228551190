import React, { useEffect, useState, memo, useCallback } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Typography, Upload, Button, Alert } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ImageReader from '../../../components/ImageReader';
import { TitleSpacement } from "../style";

const SortableItem = SortableElement(({ file, onDelete }) => (
    <ImageReader
        onDelete={() => onDelete(file)}
        file={file}
    />
));

const SortableList = SortableContainer(({ files, onDelete }) => (
    <div>
        {files.map((x, idx) => <SortableItem key={x.uid || x} onDelete={() => onDelete(idx)} index={idx} file={x} />)}
    </div>
));

const VehicleCreateImage = memo(({ values, register }) => {
    const [state, setState] = useState({ hasError: false, message: '' });
    const [files, setFiles] = useState(values || []);
    const validateFields = useCallback(async () => {
        if (files.length === 0) {
            setState({ hasError: true, message: 'O anúncio deve conter pelo menos uma imagem' });
            throw new Error("Empty images");
        }

        return files;
    }, [files, setState]);

    const catchFiles = useCallback((file) => {
        setState({ hasError: false, message: '' });
        setFiles(state => [...state, file]);

        return false;
    }, [setFiles]);

    const removeFile = useCallback((index) => {
        setFiles(state => state.filter((_, idx) => idx !== index));
    }, [setFiles]);

    const orderChange = useCallback(({ oldIndex, newIndex }) => {
        const copy = [...files];
        const [item] = copy.splice(oldIndex, 1);
        copy.splice(newIndex, 0, item);

        setFiles(copy);
    }, [files, setFiles]);

    useEffect(() => {
        register({
            validateFields,
            getFieldsValue: () => files,
        });
    }, [validateFields, files, register]);

    return (
        <>
            <Typography.Title level={4}>Imagens do produto</Typography.Title>
            <Typography.Text type="secondary">Insira imagens reais do produto, de preferencia no formato quadrado.</Typography.Text>

            <TitleSpacement />

            <Upload accept="image/*" showUploadList={false} beforeUpload={catchFiles} fileList={files} multiple>
                <Button>
                    <UploadOutlined />
                    Upload de imagens
                </Button>
            </Upload>

            {state.hasError && <>
                <TitleSpacement />
                <Alert type="error" message={state.message} showIcon />
            </>}

            <SortableList axis="xy" files={files} onDelete={removeFile} onSortEnd={orderChange} />
        </>
    );
});

export default VehicleCreateImage;