import styled, { css } from "styled-components";

const Button = styled.button`
    position: relative;
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    height: 44px;
    border-radius: 4px;
    background: transparent;
    color: #666;
    font-size: 13px;
    border: none;
    outline: none;
    padding: 0 10px;
    cursor: pointer;
    overflow: hidden;
    margin: 12px 0;

    ${p => p.smIconOnly && css`
        @media only screen and (max-width: 768px) {
            font-size: 0;

            span {
                font-size: 13px;
            }

            & > *:first-child {
                margin-right: 0 !important;
            }
        }
    `}

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 150px);
        left: calc(50% - 150px);
        width: 300px;
        height: 300px;
        border-radius: 150px;
        background: rgba(0, 0, 0, 0.1);
        transform: scale(0);
    }

    &:focus::after {
        transform: scale(1);
        transition: transform 0.15s ease-in;
    }

    & > *:first-child {
        margin-right: 10px;
    }
`;

export default Button;