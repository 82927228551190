import React, { useCallback, useState, memo, useEffect } from 'react';
import { Form, Input, Select, Modal, message } from 'antd';
import { api, useAxios } from '../../../utils/api';
import { useProfile, useUser, Profile } from '../../../utils/profile';
import { getAvailableProfiles } from '../util';
import { userToForm } from './mapper';

const UserCreate = memo(({ opened, onClose, onUpdate, user }) => {
  const cuser = useUser();
  const [form] = Form.useForm();
  const isAdmin = useProfile(Profile.Admin);
  const { data: stores } = useAxios('/store', { autoFetch: isAdmin });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(userToForm(user));
  }, [form, user]);

  const updateHandler = useCallback(async () => {
    let dto;

    try {
      dto = await form.validateFields();
    } catch (e) {
      return;
    }

    try {
      setLoading(true);
      message.loading({
        key: 'edit-user',
        content: 'Atualizando...'
      });

      dto.store = dto.store || cuser.store._id;
      if (!dto.password && dto.password !== undefined) {
        delete dto.password;
      }

      await api.put(`/user/${user._id}`, dto);

      message.success({
        key: 'edit-user',
        content: 'Usuário atualizado com sucesso',
        duration: 3
      });

      onUpdate({
        ...user,
        ...dto
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'edit-user',
        content: 'Erro ao atualizar usuário',
        duration: 3
      });
    }
  }, [onUpdate, cuser, user]);

  return (
    <Modal
      title="Editar usuário"
      visible={opened}
      confirmLoading={loading}
      onOk={updateHandler}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" name="user-edit" initialValues={userToForm(user)}>
        <Form.Item 
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input
            placeholder="Nome"
            size="large"
          />
        </Form.Item>

        <Form.Item 
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input
            placeholder="E-mail"
            size="large"
          />
        </Form.Item>

        <Form.Item 
          label="Senha"
          name="password"
        >
          <Input.Password
            placeholder="Senha"
            size="large"
          />
        </Form.Item>

        {isAdmin && (
          <Form.Item 
            label="Loja"
            name="store"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              showSearch={true}
              size="large"
              placeholder="Loja"
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {(stores || []).map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
            </Select>
          </Form.Item>
        )}

        <Form.Item 
          label="Acesso"
          name="profile"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch={true}
            size="large"
            placeholder="Perfil"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {getAvailableProfiles(user ? user.profile : '').map(x => <Select.Option key={x.value} value={x.value}>{x.name}</Select.Option>)}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default UserCreate;