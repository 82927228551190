import styled from 'styled-components';

export const ImageContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 130px;
    height: 130px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #FFF;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 1px solid #eee;
    margin: 15px 15px 0 0;
    cursor: move;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        opacity: 0;
        transition: opacity 0.3s ease-out;
        z-index: 1;
    }

    & > .ant-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0;
        transition: opacity 0.3s ease-out;
        cursor: pointer;
        z-index: 2;
    }

    &:hover::before,
    &:hover > .ant-btn {
        opacity: 1;
    }
`;