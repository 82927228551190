import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .ant-upload-picture-card-wrapper {
    width: auto !important;
  }

  .ant-upload {
    margin: 0 !important;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 150px;
  height: 150px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    opacity: 0;
    transition: opacity 0.3s;
  }

  & button {
    opacity: 0;
    margin: 0 2.5px;
  }

  &:hover::before,
  &:hover button {
    opacity: 1;
  }
`;