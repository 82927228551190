import styled, { css } from 'styled-components';

export const Drawer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: ${props => props.size};
  height: 100%;
  background: #FFF;
  transition: transform 0.3s ease-in-out;
  ${props => props.opened ? css`
    transform: translateX(0);
  ` : css`
    transform: translateX(${props => props.size});
  `}
`;

export const DrawerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out 0.15s, visibility 0s linear 0.3s;
  ${props => props.opened && css`
    opacity: 1;
    visibility: visible;
    transition: opacity 0.15s ease-in-out;
  `}
`;

export const DrawerBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 60px 20px 20px;
`;

export const DrawerTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #FFF;
  padding: 0 20px;
`;

export const DrawerFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
`;