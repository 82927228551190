import React, { useCallback, memo } from 'react';
import { Table, Popconfirm, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Card from '../../../components/Card';
import Column from 'antd/lib/table/Column';
import { api } from '../../../utils/api';
import { Action } from './style';

export default memo(({ brands, loading, onDelete, onEditClicked }) => {
  const deleteBrand = useCallback(async (brand) => {
    try {
      message.loading({
        content: 'Removendo marca...',
        key: 'remove-brand',
      });

      await api.delete(`/brand/${brand._id}`);

      onDelete(brand);

      message.success({
        content: 'Removido com sucesso',
        key: 'remove-brand',
        duration: 3,
      });
    } catch (e) {
      message.error({
        content: 'Erro ao remover loja',
        key: 'remove-brand',
        duration: 3,
      });
    }
  }, [onDelete]);

  return (
    <Card mode="table">
      <Table dataSource={brands} loading={loading} rowKey="_id">
        <Column title="Nome" key="name" dataIndex="name" />
        <Column title="" key="x" dataIndex="" render={(item) => (
          <>
            <Popconfirm
              title={`Deseja mesmo remover a marca '${item.name}'?`}
              okText="Sim"
              cancelText="Não"
              onConfirm={() => deleteBrand(item)}
              placement="left"
            >
              <Action icon={<DeleteOutlined />} />
            </Popconfirm>
            <Action icon={<EditOutlined />} onClick={() => onEditClicked(item)} />
          </>
        )} />
      </Table>
    </Card>
  );
});