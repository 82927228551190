
/**
 * Map user model to form schema.
 *
 * @export
 * @param {*} model User model.
 * @returns
 */
export function userToForm(model) {
  if (!model) {
    return {};
  }

  return {
    ...model,
    password: null,
    store: model.store._id,
  };
}