export default {
  layout: {
    navbar: {
      height: 60,
    },
    sidebar: {
      width: 280,
    },
  },
  color: {
    primary: '#007bff',
    primaryLight: '#e4eff7',
  }
};