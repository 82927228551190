import styled from 'styled-components';

export const UserBox = styled.div`
    padding: 15px;
    border-radius: 8px;
    /* border: 1px solid #DDD; */
    background: #f5f5f5;

    display: flex;
    margin: 30px 0;
    align-items: center;
`;

export const UserName = styled.p`
    line-height: 1;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    margin-bottom: 0;
`;

export const UserWelcome = styled.p`
    line-height: 1;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    color: #666;
`;

export const UserInfo = styled.div`
    padding-left: 15px;
`;