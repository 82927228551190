import React, { useCallback } from 'react';
import axios from 'axios';
import { Form, Row, Col, Input, Button } from 'antd';
import { ActionGroup } from '../../../components/ActionGroup';
import { createMask, PhoneMask, CnpjMask, IeMask, CepMask } from '../../../utils/format';
import { formToStore, storeToForm } from './mapper';

export default ({ name, values, onCancel, onFinish, disabled }) => {
    const [form] = Form.useForm();
    const fetchAddress = useCallback(async (e) => {
        try {
            const code = e.target.value.replace(/\D/, '');
            if (code.length !== 8) return;

            const { data } = await axios.get(`https://apps.widenet.com.br/busca-cep/api/cep/${code}.json`);
            form.setFieldsValue({
                address: {
                    street: data.address,
                    city: data.city,
                    state: data.state,
                    district: data.district,
                }
            });
        } catch (e) {
            console.log(e);
        }
    }, [form]);

    return (
        <Form 
            form={form} 
            name={name} 
            initialValues={storeToForm(values)} 
            layout="vertical"
            onFinish={(e) => onFinish(formToStore(e), form)}
        >
            <Form.Item 
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
                <Input
                    placeholder="Nome"
                    size="large"
                />
            </Form.Item>

            <Form.Item 
                label="Email"
                name={['contact', 'mail']}
                rules={[
                    { required: true, message: 'Campo obrigatório' },
                    { pattern: /.{3,}@.{3,}\..+/g, message: 'E-mail inválido' }
                ]}
            >
                <Input
                    placeholder="E-mail"
                    size="large"
                />
            </Form.Item>

            <Form.Item 
                label="Telefone"
                name={['contact', 'phone']}
                rules={[
                    { required: true, message: 'Campo obrigatório' },
                    { pattern: /\(\d{2}\)\s\d{4,5}-\d{4}/g, message: 'Telefone inválido' }
                ]}
                normalize={createMask(PhoneMask)}
            >
                <Input
                    placeholder="Telefone"
                    size="large"
                />
            </Form.Item>

            <Form.Item 
                label="WhatsApp"
                name={['contact', 'whatsapp']}
                rules={[
                    { required: true, message: 'Campo obrigatório' },
                    { pattern: /\(\d{2}\)\s\d{4,5}-\d{4}/g, message: 'Telefone inválido' }
                ]}
                normalize={createMask(PhoneMask)}
            >
                <Input
                    placeholder="Telefone"
                    size="large"
                />
            </Form.Item>

            <Row gutter="10">
                <Col span={12}>
                    <Form.Item 
                        label="CNPJ"
                        name="cnpj"
                        rules={[
                            { required: true, message: 'Campo obrigatório' },
                            { len: 18, message: 'CNPJ incompleto' }
                        ]}
                        normalize={createMask(CnpjMask)}
                    >
                        <Input
                            placeholder="xx.xxx.xxx/xxxx-xx"
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item 
                        label="Inscrição estadual"
                        name="ie"
                        rules={[
                            { required: true, message: 'Campo obrigatório' },
                            { len: 15, message: 'i.e. incompleto' }
                        ]}
                        normalize={createMask(IeMask)}
                    >
                        <Input
                            placeholder="xxx.xxx.xxx.xxx"
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item 
                label="CEP"
                name={['address', 'code']}
                rules={[
                    { required: true, message: 'Campo obrigatório' },
                    { len: 9, message: 'CEP incompleto' }
                ]}
                normalize={createMask(CepMask)}
            >
                <Input
                    placeholder="xxxxx-xxx"
                    size="large"
                    onChange={fetchAddress}
                />
            </Form.Item>

            <Row gutter="10">
                <Col span={8}>
                    <Form.Item 
                        label="Estado"
                        name={['address', 'state']}
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Input
                            disabled={true}
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item 
                        label="Cidade"
                        name={['address', 'city']}
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Input
                            disabled={true}
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item 
                        label="Bairro"
                        name={['address', 'district']}
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Input
                            disabled={true}
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter="10">
                <Col span={18}>
                    <Form.Item 
                        label="Logradouro"
                        name={['address', 'street']}
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Input
                            disabled={true}
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item 
                        label="Número"
                        name={['address', 'number']}
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Input
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <ActionGroup>
                <Button onClick={onCancel}>
                    Cancelar
                </Button>
                <Button disabled={disabled} htmlType="submit" type="primary">
                    Pronto
                </Button>
            </ActionGroup>
        </Form>
    );
};