import React, { useState, useCallback, useEffect, memo } from 'react';
import { Button, Row, Col, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import BrandList from './LeadList';
import { api } from '../../utils/api';
import LeadList from './LeadList';

export default memo(() => {
  const [data, setData] = useState([]);
  // const [creating, setCreating] = useState(false);
  // const [editingBrand, setEditingBrand] = useState(null);
  const [loading, setLoading] = useState(false);

  const getLeads = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/lead');

      setData(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.error({
        content: 'Erro ao carregar leads',
        key: 'load-leads',
        duration: 3
      });
    }
  }, [setLoading, setData])

  // const onNewBrand = useCallback((brand) => {
  //   setData([...data, brand]);
  //   setCreating(false);
  // }, [setData, setCreating, data]);

  // const onDelete = useCallback((brand) => {
  //   setData(
  //     data.filter(x => x._id !== brand._id)
  //   );
  // }, [setData, data]);

  // const onBrandUpdated = useCallback((brand) => {
  //   setData(
  //     data.map(x => x._id === brand._id ? brand : x)
  //   );
  //   setEditingBrand(null);
  // }, [setData, data]);

  // const openEditDrawer = useCallback((brand) => {
  //   setEditingBrand(brand);
  // }, [setEditingBrand]);

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  return (
    <Row justify="center">
      <Col xxl={16}>
        <PageTitle title="Leads" 
          //extra={
            // <Button onClick={() => setCreating(true)} icon={<PlusOutlined />} type="primary">Novo</Button>
          //} 
        />

        <LeadList brands={data} loading={loading} />

        {/* <BrandCreate opened={creating} onClose={() => setCreating(false)} onAdd={onNewBrand} />
        <BrandEdit brand={editingBrand} opened={editingBrand != null} onClose={() => setEditingBrand(null)} onUpdate={onBrandUpdated} /> */}
      </Col>
    </Row>
  );
});