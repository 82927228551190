export const Colors = [
  "branco",
  "preto",
  "vermelho",
  "prata",
  "azul",
  "amarelo",
  "verde",
  "rosa",
  "outro"
];