import { useCallback } from "react";

let lastCall = 0;
let intervalId = null;

/**
 * Debounce hook.
 *
 * @export
 * @param {*} fn Handler.
 * @param {*} time Time.
 * @param {*} deps Deps.
 * @returns
 */
export function useDebounce(fn, time) {
  const handler = useCallback((...params) => {
    // Get now time in mili.
    const now = new Date().getTime();

    // Last call + time greater than or equal now.
    if (now - lastCall >= time) {
      fn(...params);
    } else {
      const diff = now - lastCall;
      clearTimeout(intervalId);
      intervalId = setTimeout(() => fn(...params), time - diff);
    }

    // Update last call.
    lastCall = now;
  }, [time, fn]);

  return handler;
}