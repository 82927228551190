import React, { useCallback, memo } from 'react';
import { message, Dropdown, Menu, Avatar, Statistic, Modal, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Column from 'antd/lib/table/Column';
import { MoreOutlined } from '@ant-design/icons';
import Card from '../../../components/Card';
import { api } from '../../../utils/api';
import { useBreakpoint } from '../../../utils/brakpoint';
import { Table, Title, Price, Year } from './style';

const getMetric = function(item) {
  if (!item.metric) {
    return { pageViews: 0, phoneViews: 0 };
  }

  return {
    pageViews: item.metric.pageViews || 0,
    phoneViews: item.metric.phoneViews || 0,
  }
};

const goTo = (vehicle) => {
  window.open(`https://www.bordinveiculos.com.br/caminhao/${vehicle.u_title}`, '_blank');
};

export default memo(({ vehicles, loading, onDelete }) => {
  const history = useHistory();
  const smallDevice = useBreakpoint();

  const deleteVehicle = useCallback(async (vehicle) => {
    try {
      message.loading({
        content: 'Removendo veiculo...',
        key: 'remove-vehicle',
      });

      await api.delete(`/vehicle/${vehicle._id}`);

      onDelete(vehicle);

      message.success({
        content: 'Removido com sucesso',
        key: 'remove-vehicle',
        duration: 3,
      });
    } catch (e) {
      message.error({
        content: 'Erro ao remover veiculo',
        key: 'remove-vehicle',
        duration: 3,
      });
    }
  }, [onDelete]);

  const togglePauseVehicle = useCallback(async (vehicle) => {
    try {
      message.loading({
        content: 'Carregando...',
        key: 'pause-vehicle',
      });

      await api.put(`/vehicle/${vehicle._id}`, {
        ...vehicle,
        draft: !vehicle.draft,
      });

      onDelete(vehicle);

      message.success({
        content: 'Pronto',
        key: 'pause-vehicle',
        duration: 3,
      });
    } catch (e) {
      message.error({
        content: 'Erro na operação',
        key: 'pause-vehicle',
        duration: 3,
      });
    }
  }, [onDelete]);

  const deleteSafely = useCallback((vehicle) => {
    Modal.confirm({
      title: 'Tem certeza que deseja remover o anúncio: ' + vehicle.title,
      icon: <ExclamationCircleOutlined />,
      content: 'Ao remover esse anúncio, não será possível recupera-lo.',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        deleteVehicle(vehicle);
      },
    });
  }, [deleteVehicle]);

  const togglePauseSafely = useCallback((vehicle) => {
    const paused = vehicle.draft;

    Modal.confirm({
      title: `Tem certeza que deseja ${paused ? "ativar" : "pausar"} o anúncio: ${vehicle.title}`,
      icon: <ExclamationCircleOutlined />,
      content: paused ? 'Quando ativo, todos poderão ver seu anuncio.' : 'Enquanto pausado, ele não será visível a ninguém além de você.',
      okText: 'Confirmar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        togglePauseVehicle(vehicle);
      },
    });
  }, [togglePauseVehicle]);

  return (
    <Card condensed="true" mode="table">
      <Table dataSource={vehicles} loading={loading} rowKey="_id">
        <Column key="image" render={(item) => (          
          <Avatar shape="square" size={64} src={`${process.env.REACT_APP_IMAGE_URL}/${item.images[0]}`} />
        )} />
        <Column key="info" render={(item) => (
          <>
            <Title>{item.title} {item.draft && <Tag color="red">Pausado</Tag>}</Title>
            <Year>{item.manufactureYear}/{item.year}</Year>
            <Price>R$ {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Price>
          </>
        )} />
        {!smallDevice && <>
          <Column key="pageviews" render={(item) => (
            <Statistic title="Visualizações" value={getMetric(item).pageViews} />
          )} />
          <Column key="phoneviews" render={(item) => (
            <Statistic title="Interesses no telefone" value={getMetric(item).phoneViews} />
          )} />
        </>}
        <Column key="x" dataIndex="" render={(item) => (
          <Dropdown overlay={(
            <Menu>
              <Menu.Item onClick={() => goTo(item)} key="0">Ver anúncio</Menu.Item>
              <Menu.Divider />
              <Menu.Item onClick={() => togglePauseSafely(item)} key="3">{item.draft ? 'Ativar' : 'Pausar'}</Menu.Item>
              <Menu.Item onClick={() => history.push(`/vehicles/${item._id}`)} key="1">Editar</Menu.Item>
              <Menu.Item onClick={() => deleteSafely(item)} key="2">Remover</Menu.Item>
            </Menu>
          )} trigger={['click']}>
            <a href="/" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <MoreOutlined style={{ fontSize: 24 }} />
            </a>
          </Dropdown>
        )} />
      </Table>
    </Card>
  );
});