import React, { memo } from "react";
import { TitleContent } from "./style";

const Title = memo(({ content, children }) => (
    <TitleContent>
        <h1>{content}</h1>
        {children}
    </TitleContent>
));

export default Title;