import React, { useCallback, memo } from 'react';
import { DashboardOutlined, UserOutlined, CarOutlined, SettingOutlined, ShopOutlined, ContactsOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Menu } from 'antd';
import Brand from '../Brand';
import User from '../User';
import SidebarLogout from '../SidebarLogout';
import { useProfile, Profile } from '../../utils/profile';
import { Content } from './style'

function keysByRoute(path) {
    if (path === "/") return ["dash"];
    else if (path === "/brands") return ["config", "brands"];
    else if (path === "/models") return ["config", "models"];
    else if (path === "/vehicles") return ["vehicle"];
    else if (path === "/users") return ["user"];
    else if (path === "/stores") return ["store"];
    else if (path === "/leads") return ["lead"];
}

export default memo(() => {
    const router = useRouteMatch();
    const history = useHistory();
    const isAdmin = useProfile(Profile.Admin);
    const navigate = useCallback((route) => () => history.push(route), [history]);

    return (
        <>
            <Content>
                <Brand />

                <User />

                <Menu
                    mode="inline"
                    defaultSelectedKeys={keysByRoute(router.path)}
                >
                    <Menu.Item key="dash" onClick={navigate('/')}>
                        <DashboardOutlined />
                        Dashboard
                    </Menu.Item>

                    <Menu.Item key="vehicle" onClick={navigate('/vehicles')}>
                        <CarOutlined />
                        Anúncios
                    </Menu.Item>

                    <Menu.Item key="user" onClick={navigate('/users')}>
                        <UserOutlined />
                        Usuários
                    </Menu.Item>

                    <Menu.Item key="lead" onClick={navigate('/leads')}>
                        <ContactsOutlined />
                        Leads
                    </Menu.Item>

                    {isAdmin && <Menu.Item key="store" onClick={navigate('/stores')}>
                        <ShopOutlined />
                        Lojas
                    </Menu.Item>}

                    <Menu.SubMenu
                        key="config"
                        title={
                            <span>
                                <SettingOutlined />
                                Configurações
                            </span>
                        }
                    >
                        <Menu.Item key="brands" onClick={navigate('/brands')}>Marcas</Menu.Item>
                        <Menu.Item key="models" onClick={navigate('/models')}>Modelos</Menu.Item>
                    </Menu.SubMenu>
                </Menu>

            </Content>
            <SidebarLogout />
        </>
    );
});