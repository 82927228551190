import React, { memo } from "react";
import { Row, Col, Grid } from "antd";
import { EyeTwoTone, LikeTwoTone } from "@ant-design/icons";
import Card from "../../../components/Card";
import Statistic from "../../../components/Statistic";

const { useBreakpoint } = Grid;

const DashboardCards = memo(({ data }) => {
    const screens = useBreakpoint();

    return (
        <Row gutter={screens.md ? 20 : 10}>
            <Col span={12}>
                <Card marginBottom>
                    <Statistic 
                        title="Visualizações"
                        icon={<EyeTwoTone />}
                        entries={[
                            ["Página", data.pageViews],
                            ["Endereço", data.addressViews]
                        ]}
                    />
                </Card>
            </Col>
            <Col span={12}>
                <Card marginBottom>
                    <Statistic 
                        title="Interesse"
                        icon={<LikeTwoTone />}
                        entries={[
                            ["Telefone", data.phoneViews],
                            ["WhatsApp", data.socialCalls]
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    );
});

export default DashboardCards;