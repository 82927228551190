import React, { memo, useState, useEffect } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ImageContainer } from './style';

const ImageReader = memo(({ file, onDelete }) => {
    const [src, setSrc] = useState('');

    useEffect(() => {
        if (typeof file === 'string') {
            return setSrc(`${process.env.REACT_APP_IMAGE_URL}/${file}`);
        }
        
        const fr = new FileReader();
        fr.onload = (e) => setSrc(e.target.result);
        fr.readAsDataURL(file);

        return () => {
            fr.onload = null;
        };
    }, [file, setSrc])

    return (
        <ImageContainer src={src}>
            <Button onClick={onDelete} size="large">
                <DeleteOutlined />
            </Button>
        </ImageContainer>
    );
});

export default ImageReader;