import React, { useCallback, memo } from 'react';
import { Avatar } from 'antd';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined'
import { useHistory } from 'react-router-dom';

import { useDomain } from '../../utils/domain';
import { logout } from '../../domain/auth/action';
import { UserName, UserBox, UserInfo, UserWelcome } from './style';

function getInitialLetters(text) {
    const splitted = (text || '').split(/\s+/);

    return splitted.filter((_, idx) => idx === 0 || idx === splitted.length - 1).map(x => x[0]).join('');
}

const User = memo(() => {
    const { state, dispatch } = useDomain(state => state.auth);
    const history = useHistory();
    const signOut = useCallback(() => {
        history.replace('/login');
        dispatch(logout());
    }, [dispatch, history]);

    return (
        <UserBox>
            <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} size="large">{getInitialLetters(state.user.name)}</Avatar>

            <UserInfo>
                <UserWelcome>Bem vindo,</UserWelcome>
                <UserName>{state.user.name}</UserName>
            </UserInfo>
        </UserBox>
    );
});

export default User;