import React, { useCallback, memo } from 'react';
import { Table, Popconfirm, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Card from '../../../components/Card';
import Column from 'antd/lib/table/Column';
import { api } from '../../../utils/api';
import { Action } from './style';

export default memo(({ stores, loading, onDelete, onEditClicked }) => {
  const deleteStore = useCallback(async (store) => {
    try {
      message.loading({
        content: 'Removendo loja...',
        key: 'remove-store',
      });

      await api.delete(`/store/${store._id}`);

      onDelete(store);

      message.success({
        content: 'Removido com sucesso',
        key: 'remove-store',
        duration: 3,
      });
    } catch (e) {
      message.error({
        content: 'Erro ao remover loja',
        key: 'remove-store',
        duration: 3,
      });
    }
  }, [onDelete]);

  return (
    <Card mode="table">
      <Table dataSource={stores} loading={loading} rowKey="_id">
        <Column title="Nome" key="name" dataIndex="name" />
        <Column title="E-mail" key="contact.mail" dataIndex={["contact", "mail"]} />
        <Column title="Telefone" key="contact.phone" dataIndex={["contact", "phone"]} />
        <Column title="" key="x" dataIndex="" render={(item) => (
          <>
            <Popconfirm
              title={`Deseja mesmo remover a loja '${item.name}'?`}
              okText="Sim"
              cancelText="Não"
              onConfirm={() => deleteStore(item)}
              placement="left"
            >
              <Action icon={<DeleteOutlined />} />
            </Popconfirm>
            <Action icon={<EditOutlined />} onClick={() => onEditClicked(item)} />
          </>
        )} />
      </Table>
    </Card>
  );
});