import React, { useCallback, useState, memo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Spin, message, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import Card from "../../components/Card";
import { ActionContainer } from '../VehicleCreate/style';
import VehicleCreateSpec from '../VehicleCreate/VehicleCreateSpec';
import VehicleCreateMain from '../VehicleCreate/VehicleCreateMain';
import VehicleCreateImage from '../VehicleCreate/VehicleCreateImage';
import { api, useAxios } from '../../utils/api';
import { mapObject } from './map';

const VehicleEdit = memo(() => {
    const { id } = useParams();
    const history = useHistory();
    const { loading, data } = useAxios(`${process.env.REACT_APP_API_URL}/vehicle/${id}`, null, null, mapObject);
    const [state, setState] = useState({ loading: false, message: '' });
    const [forms, setForms] = useState({
        main: null,
        spec: null,
        image: null,
    });

    const registerMain = useCallback(form => {
        setForms(state => ({ ...state, main: form }));
    }, [setForms]);

    const registerImage = useCallback(form => {
        setForms(state => ({ ...state, image: form }));
    }, [setForms]);

    const registerSpec = useCallback(form => {
        setForms(state => ({ ...state, spec: form }));
    }, [setForms]);

    const save = useCallback(async () => {
        try {
            // First, validate fields.
            const images = await forms.image.validateFields();
            const main = await forms.main.validateFields();
            const spec = await forms.spec.validateFields();

            // Then, upload new images.
            const fd = new FormData();
            const uploadableImages = images.filter(x => typeof x != "string");
            
            let finalImages = images;
            if (uploadableImages.length > 0) {
                setState({ loading: true, message: "Fazendo upload das imagens" });
                for (const item of uploadableImages) {
                    fd.append(`images`, item, item.name);
                }
                
                const { data: result } = await api.post(`${process.env.REACT_APP_API_URL}/vehicle/${id}/images`, fd, {
                    headers: {
                    'content-type': 'multipart/form-data',
                    },
                });

                // Comparing new images array, overriding File by image path.
                const newImages = result.images.slice(result.images.length - uploadableImages.length, result.images.length);
                finalImages = images.map(x => {
                    if (typeof x !== "string") return newImages.shift();
    
                    return x;
                });
            }

            // Finally, update info.
            setState({ loading: true, message: "Salvando informações" });
            await api.put(`${process.env.REACT_APP_API_URL}/vehicle/${id}`, {
                ...main,
                ...spec,
                images: finalImages,
            });

            setState({ loading: false });
            message.success({
                content: 'Anúncio atualizado',
                duration: 4,
                key: id,
            });
        
            history.push('/vehicles');
        } catch (e) {
            console.log(e);
            setState({ loading: false, message: '' });
            message.error({
                content: e.message || 'Erro ao salvar anúncio',
                duration: 5,
                key: 'save-vehicle-error',
            });
        }
    }, [forms, history, id]);

    if (loading || !data) {
        return (
            <>
                <PageTitle title="Editar anúncio" />
                <Spin spinning tip="Carregando" />
            </>
        );
    }

    return (
        <>
            <PageTitle title="Editar anúncio" />
            <Spin spinning={state.loading} tip={state.message}>
                <Row gutter={20}>
                    <Col lg={10}>
                        <Card>
                            <VehicleCreateMain register={registerMain} values={data} />
                            <VehicleCreateImage register={registerImage} values={data.images} />
                        </Card>
                    </Col>
                    <Col lg={14}>
                        <Card>
                            <VehicleCreateSpec register={registerSpec} values={data} />
                        </Card>
                    </Col>
                </Row>
            </Spin>
            <ActionContainer>
                <Button size="large" type="link" disabled={state.loading} onClick={() => history.push('/vehicles')}>
                    Cancelar
                </Button>
                <Button onClick={save} size="large" disabled={state.loading} type="primary">
                    Salvar
                </Button>
            </ActionContainer>
        </>
    );
});

export default VehicleEdit;