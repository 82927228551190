import styled from "styled-components";

export const StepContainer = styled.div`
  margin: 50px 0 30px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  margin: 20px 0;
`;

export const TitleSpacement = styled.div`
  margin-bottom: 20px;
`;