import React, { useState, useCallback, useEffect, memo } from 'react';
import { Button, Row, Col, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import ModelList from './ModelList';
import ModelCreate from './ModelCreate';
import ModelEdit from './ModelEdit';
import { api } from '../../utils/api';

export default memo(() => {
  const [data, setData] = useState([]);
  const [creating, setCreating] = useState(false);
  const [editingModel, setEditingModel] = useState(null);
  const [loading, setLoading] = useState(false);

  const getModels = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/model');

      setData(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.error({
        content: 'Erro ao carregar modelos',
        key: 'load-models',
        duration: 3
      });
    }
  }, [setLoading, setData])

  const onNewModel = useCallback((model) => {
    setData([...data, model]);
    setCreating(false);
  }, [setData, setCreating, data]);

  const onDelete = useCallback((model) => {
    setData(
      data.filter(x => x._id !== model._id)
    );
  }, [setData, data]);

  const onModelUpdated = useCallback((model) => {
    setData(
      data.map(x => x._id === model._id ? model : x)
    );
    setEditingModel(null);
  }, [setData, data]);

  const openEditDrawer = useCallback((model) => {
    setEditingModel(model);
  }, [setEditingModel]);

  useEffect(() => {
    getModels();
  }, [getModels]);

  return (
    <Row justify="center">
      <Col xxl={16}>
        <PageTitle title="Modelos" extra={
          <Button onClick={() => setCreating(true)} icon={<PlusOutlined />} type="primary">Novo</Button>
        } />

        <ModelList models={data} loading={loading} onDelete={onDelete} onEditClicked={openEditDrawer} />

        <ModelCreate opened={creating} onClose={() => setCreating(false)} onAdd={onNewModel} />
        <ModelEdit model={editingModel} opened={editingModel != null} onClose={() => setEditingModel(null)} onUpdate={onModelUpdated} />
      </Col>
    </Row>
  );
});