import { api } from "../../utils/api";

export const LOGIN_ACTION = "SET-AUTH";
export const login = (token, user) => {
    api.defaults.headers['Authorization'] = token;

    return {
        type: LOGIN_ACTION,
        value: { token, user }
    };
};

export const LOGOUT_ACTION = "UNSET-AUTH";
export const logout = () => ({
    type: LOGOUT_ACTION
});
