import { OPEN_SIDEBAR_TOKEN, CLOSE_SIDEBAR_TOKEN } from "./action";

export default function(state, action) {
    switch (action.type) {
        case OPEN_SIDEBAR_TOKEN:
            return {
                ...state,
                sidebar: {
                    open: true,
                },
            };
        case CLOSE_SIDEBAR_TOKEN:
            return {
                ...state,
                sidebar: {
                    open: false,
                },
            };
        default:
            return null;
    }
}
