import styled from "styled-components";

export const IconWrapper = styled.div`
    font-size: 32px;
    text-align: center;
`;

export const TitleWrapper = styled.p`
    display: block;
    width: 100%;
    font-size: 16px;
    color: #333;
    font-weight: 400;
    text-align: center;
`;

export const NumberWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @media screen and (max-width: 767px) {
        margin-top: 5px;
    }
`;

export const NumberItem = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;

    font-size: 32px;
    color: #000;
    font-weight: 500;
    text-align: center;    

    span {
        display: block;
        width: 100%;
        font-size: 16px;
        color: #666;
        font-weight: 300;
    }

    @media screen and (max-width: 767px) {
        font-size: 24px;

        span {
            font-size: 12px;
        }
    }
`;