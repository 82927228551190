import React from "react";
import styled from "styled-components";

const Tag = styled.div`
    display: inline-block;
    padding: 5px;
    background: #FFF;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    color: #333;
    font-size: 12px;
    border-radius: 3px;
    font-weight: 300;

    strong {
        font-weight: 500;
    }
`;

export const AreaConfiguration = {
    margin: { top: 20, right: 20, bottom: 20, left: 20 },
    xScale: { type: 'point' },
    yScale: { type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false },
    axisTop: null,
    axisRight: null,
    axisBottom: {
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
    },
    axisLeft: {
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
    },
    enableGridX: false,
    enableGridY: false,
    colors: { scheme: 'nivo' },
    pointSize: 10,
    pointColor: { theme: 'background' },
    pointBorderWidth: 2,
    pointBorderColor: { from: 'serieColor' },
    pointLabel: "y",
    pointLabelYOffset: -12,
    enableArea: true,
    areaOpacity: 1,
    useMesh: true,
    tooltip: (value) => {
        const point = value.point;
        return (
            <Tag color="default"><strong>{point.serieId}</strong>: {point.data.y}</Tag>
        );
    },
    legends: [
        {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 10,
            translateY: -28,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 110,
            itemHeight: 28,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1
                    }
                }
            ]
        }
    ],
    motionStiffness: 85,
};