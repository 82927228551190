import React, { useState, useCallback, useEffect, memo } from 'react';
import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import StoreList from './StoreList';
import StoreCreate from './StoreCreate';
import { api } from '../../utils/api';
import StoreEdit from './StoreEdit';

export default memo(() => {
  const [data, setData] = useState([]);
  const [creating, setCreating] = useState(false);
  const [editingStore, setEditingStore] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStores = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/store');

      setData(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.error({
        content: 'Erro ao carregar lojas',
        key: 'load-stores',
        duration: 3
      });
    }
  }, [setLoading, setData])

  const onNewStore = useCallback((store) => {
    setData([...data, store]);
    setCreating(false);
  }, [setData, setCreating, data]);

  const onDelete = useCallback((store) => {
    setData(
      data.filter(x => x._id !== store._id)
    );
  }, [setData, data]);

  const onStoreUpdated = useCallback((store, keepOpen) => {
    setData(
      data.map(x => x._id === store._id ? store : x)
    );

    if (!keepOpen) {
      setEditingStore(null);
    }
  }, [setData, data]);

  const openEditDrawer = useCallback((store) => {
    setEditingStore(store);
  }, [setEditingStore]);

  useEffect(() => {
    getStores();
  }, [getStores]);

  return (
    <>
      <PageTitle title="Lojas" extra={
        <Button onClick={() => setCreating(true)} icon={<PlusOutlined />} type="primary">Novo</Button>
      } />

      <StoreList stores={data} loading={loading} onDelete={onDelete} onEditClicked={openEditDrawer} />

      <StoreCreate opened={creating} onClose={() => setCreating(false)} onAdd={onNewStore} />
      <StoreEdit store={editingStore} opened={editingStore != null} onClose={() => setEditingStore(null)} onUpdate={onStoreUpdated} />
    </>
  );
});