import styled from "styled-components";

export const TitleContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;

    h1 {
        font-size: 26px;
        color: #333;
        margin-bottom: 0;
        line-height: 1;
    }
`;