import React, { useState, useCallback, useEffect, memo } from 'react';
import { Button, message, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import UserList from './UserList';
import UserCreate from './UserCreate';
import { api } from '../../utils/api';
import UserEdit from './UserEdit';
import { useProfile, Profile } from '../../utils/profile';

export default memo(() => {
  const cannotAddUser = useProfile(Profile.StoreMember);
  const [data, setData] = useState([]);
  const [creating, setCreating] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUsers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/user');

      setData(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.error({
        content: 'Erro ao carregar usuários',
        key: 'load-users',
        duration: 3
      });
    }
  }, [setLoading, setData])

  const onNewUser = useCallback((user) => {
    setData([...data, user]);
    setCreating(false);
  }, [setData, setCreating, data]);

  const onDelete = useCallback((user) => {
    setData(
      data.filter(x => x._id !== user._id)
    );
  }, [setData, data]);

  const onUserUpdated = useCallback((user) => {
    setData(
      data.map(x => x._id === user._id ? user : x)
    );
    setEditingUser(null);
  }, [setData, data]);

  const openEditDrawer = useCallback((user) => {
    setEditingUser(user);
  }, [setEditingUser]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Row justify="center">
      <Col xxl={16}>
        <PageTitle title="Usuários" extra={
          !cannotAddUser && <Button onClick={() => setCreating(true)} icon={<PlusOutlined />} type="primary">Novo</Button>
        } />

        <UserList users={data} loading={loading} onDelete={onDelete} onEditClicked={openEditDrawer} />

        <UserCreate opened={creating} onClose={() => setCreating(false)} onAdd={onNewUser} />
        <UserEdit user={editingUser} opened={editingUser != null} onClose={() => setEditingUser(null)} onUpdate={onUserUpdated} />
      </Col>
    </Row>
  );
});