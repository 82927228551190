import React, { useState, useCallback } from 'react';
import { Row, Col, Tabs } from 'antd';
import Title from '../../components/Title';
import Select from '../../components/Select';
import { Profile, useProfile } from '../../utils/profile';
import { periodToQuery } from './period';

import DashboardAdmin from '../DashboardAdmin';
import DashboardStore from '../DashboardStore';

export default () => {
    const isAdmin = useProfile(Profile.Admin);
    const [curr, setCurr] = useState("1");
    const [period, setPeriod] = useState("month");
    const [query, setQuery] = useState(periodToQuery("month"));

    const onPeriodChange = useCallback((e) => {
        setPeriod(e.target.value);
        setQuery(periodToQuery(e.target.value));
    }, [setPeriod, setQuery]);

    const onTabSelect = useCallback((e) => {
        setCurr(e);
    }, [setCurr]);

    if (isAdmin) {
        return (
            <Row justify="center">
                <Col xxl={16} span={24}>
                    <Title content="Dashboard">
                        <Select width="120px" value={period} onChange={onPeriodChange}>
                            <option value="month">Mensal</option>
                            <option value="quarter">Trimestral</option>
                        </Select>
                    </Title>
    
                    <Tabs defaultActiveKey={curr} animated={false} onChange={onTabSelect}>
                        <Tabs.TabPane tab="Admin" key="1">
                            <DashboardAdmin query={query} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Loja" key="2">
                            <DashboardStore query={query} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        );
    }

    return (
        <Row justify="center">
            <Col xxl={16} span={24}>
                <Title content="Dashboard">
                    <Select width="120px" value={period} onChange={onPeriodChange}>
                        <option value="month">Mensal</option>
                        <option value="quarter">Trimestral</option>
                    </Select>
                </Title>

                <DashboardStore query={query} />
            </Col>
        </Row>
    );
}