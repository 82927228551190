import React, { memo, useEffect, useState } from "react";
import { ResponsiveLine } from '@nivo/line';
import { AreaConfiguration } from '../../../utils/chart';
import Card from "../../../components/Card";
import { ChartWrapper } from './style';
import { mapDataToChart } from './mapper';

const DashboardChart = memo(({ bucket }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const parsed = mapDataToChart(bucket);
        setData(parsed);
    }, [bucket, setData]);

    return (
        <Card title="Evolução" marginBottom>
            <ChartWrapper>
                <ResponsiveLine
                    data={data}
                    {...AreaConfiguration}
                />
            </ChartWrapper>
        </Card>
    );
});

export default DashboardChart;