import { useState, useEffect } from "react";
import { useDomain } from "../utils/domain";

export const Profile = {
  StoreOwner: "StoreOwner",
  StoreMember: "StoreMember",
  Admin: "Admin",
};

export const ProfileMap = {
  [Profile.Admin]: 'Administrador',
  [Profile.StoreOwner]: 'Gerente da Loja',
  [Profile.StoreMember]: 'Usuário comum',
};

/**
 * Return user profile validation.
 *
 * @export
 * @param {*} profileType
 */
export function useProfile(profileType) {
  const { state } = useDomain(state => state.auth);
  const [hasProfile, setHasProfile] = useState(state && state.user && state.user.profile === profileType);

  useEffect(() => {
    if (state.user.profile === profileType) {
      if (!hasProfile) {
        setHasProfile(true);
      }
    } else {
      setHasProfile(false);
    }
  }, [profileType, hasProfile, state]);

  return hasProfile;
}

/**
 * Return user.
 *
 * @export
 * @returns
 */
export function useUser() {
  const { state } = useDomain(state => state.auth);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(state.user);
  }, [state]);

  return user;
}