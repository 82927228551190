import styled, { css } from 'styled-components';
import { Card as AntCard } from 'antd';

const Card = styled(AntCard)`
  &.ant-card {
    background: #FFF;
    box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.4);
    margin-top: ${p => p.condensed ? "0" : "25px"};
    border-radius: 10px;
    overflow: hidden;

    ${p => p.marginBottom && css`
      margin-top: 0;
      margin-bottom: 15px;
    `}
  }

  @media only screen and (max-width: 767px) {
    .ant-card-body {
      padding: 10px;
    }
  }

  ${props => props.mode === 'table' ? css`
    .ant-card-body {
      padding: 0;
    }

    .ant-pagination {
      padding-right: 60px;
    }

    .ant-tabs-tab {
      padding: 12px 10px !important;
    }
  ` : ''}
`;

export default Card;