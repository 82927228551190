import React, { useCallback, memo } from 'react';
import { Editor as Tiny } from '@tinymce/tinymce-react';
import { useDebounce } from '../../utils/debounce';

const Editor = memo(({ value, onChange, size }) => {
  // On content change.
  const updateContent = useCallback((content) => onChange(content), [onChange]);
  const onContentChange = useDebounce(updateContent, 500, [onChange]);

  return (
    <Tiny
      apiKey="7lewua1qas1mgs4xbbtkem9ir18imoi6drltgb86yf4i4wfo"
      initialValue={value}
      init={{
        height: size || 300,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          `undo redo | formatselect | bold italic backcolor |
          alignleft aligncenter alignright alignjustify |
          bullist numlist outdent indent | removeformat | help`
      }}
      onEditorChange={onContentChange}
    />
  );
});

export default Editor;