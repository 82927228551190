// Layouts
import Default from '../../layout/Default';
import Auth from '../../layout/Auth';

// Pages
import Login from '../Login';
import Dashboard from '../Dashboard';
import Store from '../Store';
import User from '../User';
import Brand from '../Brand';
import Model from '../Model';
import Vehicle from '../Vehicle';
import VehicleCreate from '../VehicleCreate';
import VehicleEdit from '../VehicleEdit';
import Lead from '../Lead';

export const routes = [
    { path: "/login", component: Auth(Login) },
    { path: "/", component: Default(Dashboard) },
    { path: "/stores", component: Default(Store) },
    { path: "/users", component: Default(User) },
    { path: "/brands", component: Default(Brand) },
    { path: "/leads", component: Default(Lead) },
    { path: "/models", component: Default(Model) },
    { path: "/vehicles", component: Default(Vehicle) },
    { path: "/vehicles/new", component: Default(VehicleCreate) },
    { path: "/vehicles/:id", exact: false, component: Default(VehicleEdit) },
];