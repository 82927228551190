import React, { useCallback, useState, memo } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import { ActionGroup } from '../../../components/ActionGroup';
import { api } from '../../../utils/api';

const BrandCreate = memo(({ opened, onClose, onAdd }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  
  const addHandler = useCallback(async () => {
    const { name } = await form.validateFields();

    try {
      setLoading(true);
      message.loading({
        key: 'add-brand',
        content: 'Adicionando...'
      });

      const { data } = await api.post('/brand', {
        name
      });

      message.success({
        key: 'add-brand',
        content: 'Marca adicionada com sucesso',
        duration: 3
      });

      form.resetFields();
      onAdd(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'add-brand',
        content: 'Erro ao adicionar marca',
        duration: 3
      });
    }
  }, [form, onAdd]);

  return (
    <Modal
      title="Adicionar marca"
      visible={opened}
      confirmLoading={loading}
      onOk={addHandler}
      onCancel={onClose}
    >
      <Form form={form} name="brand-create" layout="vertical">

        <Form.Item 
          label="Nome"
          name="name"
          rules={
            [{ required: true, message: 'Campo obrigatório' }]
          }
        >
          <Input
            placeholder="Nome"
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default BrandCreate;