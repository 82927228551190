export const OPEN_LOADING_TOKEN = "OPEN-LOADING";
export function openLoading(locale) {
    return {
        type: OPEN_LOADING_TOKEN,
        value: locale,
    };
}

export const CLOSE_LOADING_TOKEN = "CLOSE-LOADING";
export function closeLoading() {
    return {
        type: CLOSE_LOADING_TOKEN,
    };
}