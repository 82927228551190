export const OPEN_SIDEBAR_TOKEN = "OPEN-SIDEBAR";
export function openSidebar() {
    return {
        type: OPEN_SIDEBAR_TOKEN,
    };
}

export const CLOSE_SIDEBAR_TOKEN = "CLOSE-SIDEBAR";
export function closeSidebar() {
    return {
        type: CLOSE_SIDEBAR_TOKEN,
    };
}