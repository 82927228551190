import React, { useCallback, useState, memo } from 'react';
import { Form, Input, Select, Modal, message } from 'antd';
import { api, useAxios } from '../../../utils/api';

const ModelEdit = memo(({ model, opened, onClose, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { data: brands } = useAxios('/brand');

  const editHandler = useCallback(async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch(e) {
      return;
    }

    try {
      setLoading(true);
      message.loading({
        key: 'edit-model',
        content: 'Atualizando...'
      });

      const result = await api.put(`/model/${model._id}`, data);

      message.success({
        key: 'edit-model',
        content: 'Modelo atualizada com sucesso',
        duration: 3
      });

      onUpdate(result.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'edit-model',
        content: 'Erro ao atualizar modelo',
        duration: 3
      });
    }
  }, [onUpdate, model, form]);

  if (!model) {
    return null;
  }

  return (
    <Modal
      title="Editar modelo"
      visible={opened}
      confirmLoading={loading}
      onOk={editHandler}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical" name="edit-model" initialValues={{ name: model.name, brand: model.brand._id }}>
        <Form.Item 
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input
            placeholder="Nome"
            size="large"
          />
        </Form.Item>

        <Form.Item 
          label="Marca"
          name="brand"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Digite uma marca"
            size="large"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(brands || []).map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ModelEdit;