import React, { useCallback, useState, memo } from 'react';
import { message } from 'antd';
import LazyDrawer from '../../../components/LazyDrawer';
import { api } from '../../../utils/api';
import StoreEditAvatar from '../StoreEditAvatar';
import StoreForm from '../StoreForm';

const StoreEdit = memo(({ store, opened, onClose, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const editHandler = useCallback(async (dto) => {
    try {
      setLoading(true);
      message.loading({
        key: 'edit-store',
        content: 'Atualizando...'
      });

      const { data } = await api.put(`/store/${store._id}`, dto);

      message.success({
        key: 'edit-store',
        content: 'Loja atualizada com sucesso',
        duration: 3
      });

      onUpdate({
        ...data,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'edit-store',
        content: 'Erro ao atualizar loja',
        duration: 3
      });
    }
  }, [onUpdate, store]);

  const onLogoChange = useCallback(async (info) => {
    if (info != null) {
      return onUpdate(info, true);
    }

    try {
      message.loading({
        key: 'remove-logo',
        content: 'Removendo...'
      });

      const { data } = await api.put(`/store/${store._id}`, { logo: null });

      message.success({
        key: 'remove-logo',
        content: 'Logo removido com sucesso',
        duration: 3
      });

      onUpdate({
        ...data,
        logo: null,
      }, true);
    } catch (e) {
      message.error({
        key: 'remove-logo',
        content: 'Erro ao remover logo',
        duration: 3
      });
    }
  }, [onUpdate, store]);

  return (
    <LazyDrawer
      title="Editar loja"
      size="480px"
      opened={opened}
      onClose={onClose}
    >
      <StoreEditAvatar storeId={store && store._id} logo={store && store.logo} onChange={onLogoChange} />

      <StoreForm 
        name="update-store" 
        values={store} 
        onFinish={editHandler} 
        onCancel={onClose} 
        disabled={loading} 
      />
    </LazyDrawer>
  );
});

export default StoreEdit;