import React, { memo, useState, useCallback } from 'react';
import { Upload, Button } from 'antd';
import { DeleteOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Auth } from '../../../utils/auth';
import { Image, Container } from './style';

export default memo(({ storeId, logo, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(logo);

  const onStatusChange = useCallback((info) =>{
    if (info.file.status === 'uploading') {
      setLoading(true);
    } else if (info.file.status === 'done') {
      const logo = info.file.response.logo;
      setLoading(false);
      setImage(logo);
      onChange(info.file.response);
    }
  }, [setLoading, setImage, onChange]);

  const onLogoRemoved = useCallback((e) => {
    e.stopPropagation();
    onChange(null);
    setImage(null);
  }, [onChange, setImage]);

  return (
    <Container>
      <Upload
        action={`${process.env.REACT_APP_API_URL}/store/${storeId}/image`} 
        headers={{
          Authorization: Auth.getStorageData().token
        }}
        listType="picture-card"
        showUploadList={false}
        onChange={onStatusChange}
        name="image"
      >
        {!loading && image ? <Image src={`${process.env.REACT_APP_AVATAR_URL}/${image}`}>
          <Button onClick={onLogoRemoved} icon={<DeleteOutlined />} />
          <Button icon={<UploadOutlined />} />
        </Image> : <>
          {loading ? <LoadingOutlined /> : <p>Upload</p> }
        </>}
      </Upload>
    </Container>
  );
});