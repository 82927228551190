import { OPEN_LOADING_TOKEN, CLOSE_LOADING_TOKEN } from "./action";

export default function(state, action) {
    switch (action.type) {
        case OPEN_LOADING_TOKEN:
            return {
                ...state,
                loading: {
                    count: state.loading.count + 1,
                    locale: action.value,
                },
            };
        case CLOSE_LOADING_TOKEN:
            return {
                ...state,
                loading: {
                    count: Math.max(state.loading.count - 1, 0),
                    locale: state.loading.locale,
                },
            };
        default:
            return null;
    }
}
