import styled, { css } from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 0;
`;

export const MainSection = styled.main`
    display: inline-block;
    width: ${p => `calc(100% - ${p.theme.layout.sidebar.width}px)`};
    margin-left: ${p => p.theme.layout.sidebar.width}px;
    height: 100%;
    font-size: 13px;
    overflow: auto;
    padding: 24px;
    padding-top: ${p => p.theme.layout.navbar.height + 10}px;
    background: rgb(243, 244, 249);

    @media only screen and (max-width: 1024px) {
        width: 100%;
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: ${p => p.theme.layout.navbar.height + 25}px;
    }
`;

export const SidebarMobileOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 998;
    background: rgba(255, 255, 255, 0.45);
    transition: opacity 0.3s ease-in, visibility 0s linear 0.3s;

    ${p => p.visible && css`
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease-out;
    `}

    @media only screen and (min-width: 1025px) {
        display: none;
    }
`;

export const SidebarSection = styled.aside`
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    font-size: 13px;
    height: 100%;
    width: ${p => p.theme.layout.sidebar.width}px;
    vertical-align: top;
    background: #FFF;
    border-right: 1px solid #EEE;
    z-index: 1000;
    box-shadow: 3px 0 15px -4px rgb(0 0 0 / 15%);
    padding-bottom: 73px;

    & .ant-menu {
        border-right: none;
    }

    & .ant-menu-inline .ant-menu-item-selected::after {
        display: none;
    }

    & .ant-menu-item {
        border-radius: 4px;
    }

    & .ant-menu-inline > .ant-menu-item {
        height: 50px;
        line-height: 50px;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 4px;
    }

    @media only screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        transition: transform 0.3s ease-out;

        ${p => !p.open && css`
            transform: translateX(-100%);            
        `}
    }
`;

export const NavbarSection = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    height: 60px;
    font-size: 13px;
    vertical-align: top;
    z-index: 999;
    background: rgb(243,244,249, 0.8);
    box-shadow: 0 8px 8px rgb(243,244,249);
    backdrop-filter: blur(8px);
    padding: 0 24px;

    @media only screen and (min-width: 1025px) {
        justify-content: flex-end;
        width: ${p => `calc(100% - ${p.theme.layout.sidebar.width}px)`};
    }
`;