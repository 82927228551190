import authReducer from "./auth/reducer";
import loadingReducer from "./loading/reducer";
import sidebarReducer from "./sidebar/reducer";

const reducers = [
  authReducer,
  loadingReducer,
  sidebarReducer,
];

export default function(state, action) {
  for (const fn of reducers) {
    const result = fn(state, action);

    if (result != null) {
      window.currentDomain = result;
      return result;
    }
  }

  return state;
}
