import * as moment from "moment";

export function mapDataToChart(data) {
    const addressViews = { id: "Int. endereço", data: [] };
    const pageViews = { id: "Vis. página", data: [] };
    const phoneViews = { id: "Int. telefone", data: [] };
    const socialCalls = { id: "Int. whatsapp", data: [] };

    for (const item of data) {
        addressViews.data.push({
            x: moment(item._id).format('DD/MM/YYYY'),
            y: item.addressViews,
        });

        pageViews.data.push({
            x: moment(item._id).format('DD/MM/YYYY'),
            y: item.pageViews,
        });

        phoneViews.data.push({
            x: moment(item._id).format('DD/MM/YYYY'),
            y: item.phoneViews,
        });

        socialCalls.data.push({
            x: moment(item._id).format('DD/MM/YYYY'),
            y: item.socialCalls,
        });
    }

    return [
        addressViews,
        pageViews,
        phoneViews,
        socialCalls,
    ];
}