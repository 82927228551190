import React, { useState, memo, useEffect } from 'react';
import { AutoComplete } from 'antd';

const Autocomplete = memo(({ options, value, onChange, ...rest }) => {
  const [items, setItems] = useState(options.map(x => ({ value: x })));

  // On options change.
  useEffect(() => {
    setItems(
      options.filter(
        x => !!x.toLowerCase().match((value || "").toLowerCase()),
      ).map(x => ({ value: x })),
    );
  }, [options, value, setItems]);

  return (
    <AutoComplete 
      options={items}
      onSearch={val => onChange(val)}
      onSelect={val => onChange(val)}
      value={value}
      {...rest}
    />
  );
});

export default Autocomplete;