import { Auth } from "../../utils/auth";
import { LOGIN_ACTION, LOGOUT_ACTION } from "./action";

export default function(state, action) {
    switch (action.type) {
        case LOGIN_ACTION:
            Auth.setStorageData(action.value.token, action.value.user);

            return {
                ...state,
                auth: action.value
            };
        case LOGOUT_ACTION: 
            Auth.deleteStorageData();

            return {
                ...state,
                auth: {
                    token: null,
                    user: null
                }
            };
        default:
            return null;
    }
}
