import React, { useCallback, useState, memo } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { api } from '../../../utils/api';

const BrandEdit = memo(({ brand, opened, onClose, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const editHandler = useCallback(async () => {
    let data;
    try {
      data = await form.validateFields();
    } catch (e) {
      return;
    }

    try {
      setLoading(true);
      message.loading({
        key: 'edit-brand',
        content: 'Atualizando...'
      });

      await api.put(`/brand/${brand._id}`, data);

      message.success({
        key: 'edit-brand',
        content: 'Marca atualizada com sucesso',
        duration: 3
      });

      onUpdate({
        ...brand,
        name: data.name,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'edit-brand',
        content: 'Erro ao atualizar marca',
        duration: 3
      });
    }
  }, [onUpdate, brand, form]);

  return (
    <Modal
      title="Editar marca"
      confirmLoading={loading}
      visible={opened}
      onCancel={onClose}
      onOk={editHandler}
    >
      <Form form={form} name="edit-brand" initialValues={brand}>
        <Form.Item 
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input
            placeholder="Nome"
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default BrandEdit;