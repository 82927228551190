import React, { useEffect, useState, useCallback, memo } from 'react';
import { Form, Button, Row, Col, Select, InputNumber, Typography, Divider } from "antd";
import { PlusOutlined } from '@ant-design/icons';
// Utils
import { useAxios } from '../../../utils/api';
// Models and contants
import { Colors } from '../../../models/vehicle/color';
import { Engine } from '../../../models/vehicle/engine';
import { Equipment } from '../../../models/vehicle/equipment';
import { VehicleType } from '../../../models/vehicle/type';
// Components
import Autocomplete from '../../../components/Autocomplete';
import Editor from '../../../components/Editor';
import { TitleSpacement } from "../style";
import BrandCreate from '../../Brand/BrandCreate';
import ModelCreate from '../../Model/ModelCreate';

const VehicleCreateSpec = memo(({ values, register }) => {
    const [form] = Form.useForm();
    const [creatingBrand, setCreatingBrand] = useState(false);
    const [creatingModel, setCreatingModel] = useState(false);
    const [brand, setBrand] = useState(values && values.brand);
    const { data: brands, fetch: refetchBrand } = useAxios('/brand', null, []);
    const { data: models, fetch: refetchModel } = useAxios('/model', null, []);

    const onCreateBrand = useCallback((val) => {
        setCreatingBrand(false);
        refetchBrand();
    }, [refetchBrand, setCreatingBrand]);

    const onCreateModel = useCallback((val) => {
        setCreatingModel(false);
        refetchModel();
    }, [refetchModel, setCreatingModel]);

    const onCreateCancel = useCallback(() => {
        setCreatingBrand(false);
        setCreatingModel(false);
    }, [setCreatingBrand, setCreatingModel]);

    const onBrandChanged = useCallback((val) => {
        setBrand(val);
        form.setFieldsValue({ vmodel: null });
        console.log(form.getFieldsValue());
    }, [setBrand, form]);

    useEffect(() => {
        register(form);
    }, [form, register]);

    return (
        <Form form={form} initialValues={values || {}} layout="vertical" name="vehicle-create-spec">
            <Typography.Title level={4}>Informações do modelo</Typography.Title>
            <Typography.Text type="secondary">Seja preciso no modelo do veículo anúnciado, essas informações são usadas na busca do usuário.</Typography.Text>

            <TitleSpacement />

            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item 
                        label="Marca"
                        name="brand"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Digite uma marca"
                            onChange={onBrandChanged}
                            size="large"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {brands.map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Button onClick={() => setCreatingBrand(true)} icon={<PlusOutlined />} type="link">Nova marca</Button>                        

                    <BrandCreate 
                        opened={creatingBrand}
                        onAdd={onCreateBrand}
                        onClose={onCreateCancel}
                    />
                </Col>
                <Col span={12}>
                    <Form.Item 
                        label="Modelo"
                        name="vmodel"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Digite um modelo"
                            size="large"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {models.filter(x => x.brand._id === brand).map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    
                    <Button onClick={() => setCreatingModel(true)} icon={<PlusOutlined />} type="link">Novo modelo</Button>                        

                    <ModelCreate 
                        opened={creatingModel}
                        onAdd={onCreateModel}
                        onClose={onCreateCancel}
                    />
                </Col>
            </Row>

            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item 
                        label="Ano fabricação"
                        name="manufactureYear"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <InputNumber
                        placeholder="Ano"
                        min={1900}
                        max={new Date().getFullYear() + 1}
                        value={new Date().getFullYear()}
                        size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item 
                        label="Ano modelo"
                        name="year"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <InputNumber
                        placeholder="Ano"
                        min={1900}
                        max={new Date().getFullYear() + 1}
                        value={new Date().getFullYear()}
                        size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Divider />

            <Typography.Title level={4}>Especificações secundárias</Typography.Title>
            <Typography.Text type="secondary">Essas informações serão exibidas ao cliente na página do seu anúncio.</Typography.Text>
            
            <TitleSpacement />

            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item 
                        label="Tipo"
                        name="type"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Autocomplete
                            options={VehicleType}
                            placeholder="Selecione"
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item 
                        label="Equipamento"
                        name="equipment"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Autocomplete
                            options={Equipment}
                            placeholder="Selecione"
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item 
                        label="Motor"
                        name="engine"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Autocomplete
                            options={Engine}
                            placeholder="Selecione"
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item 
                        label="Cor"
                        name="color"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Autocomplete
                            options={Colors}
                            placeholder="Selecione"
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item 
                        label="Quilometros"
                        name="kilometers"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <InputNumber
                            placeholder="Quilometros"
                            min={0}
                            max={10000000}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            parser={value => value.replace(/(\.*)/g, '')}
                            value={0}
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Divider />

            <Typography.Title level={4}>Descrição</Typography.Title>
            <Typography.Text type="secondary">Campo aberto para adicionar informações relevantes adicionais.</Typography.Text>

            <TitleSpacement />

            <Form.Item
                name="description"
            >
                <Editor />
            </Form.Item>
        </Form>
    );
});

export default VehicleCreateSpec;