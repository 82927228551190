import React, { useEffect, memo } from "react";
import { Typography, Input, Select, InputNumber, Divider, Form } from "antd";
import { useAxios } from "../../../utils/api";
import { Profile, useProfile } from "../../../utils/profile";
import { InputContainer } from "../style";

const VehicleCreateMain = memo(({ values, register }) => {
    const [form] = Form.useForm();
    const isAdmin = useProfile(Profile.Admin);
    const { data: stores } = useAxios('/store', { autoFetch: isAdmin });

    useEffect(() => {
        register(form);
    }, [form, register]);

    return (
        <Form form={form} name="vehicle-create-main" initialValues={values || {}}>
            {isAdmin && <>
                <Typography.Title level={4}>Escolha a loja</Typography.Title>
                <Typography.Text type="secondary">Essa opção só é disponível para administradores do sistemna.</Typography.Text>

                <InputContainer>
                    <Form.Item
                        name="store"
                        rules={[{ required: true, message: 'Campo obrigatório' }]}
                    >
                        <Select
                            showSearch={true}
                            size="large"
                            placeholder="Loja"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {(stores || []).map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </InputContainer>

                <Divider />
            </>}

            <Typography.Title level={4}>Insira o titulo</Typography.Title>
            <Typography.Text type="secondary">Será exibido para todos os usuários do site, seja objetivo e claro sobre produto anúnciado.</Typography.Text>

            <InputContainer>
                <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                    <Input size="large" placeholder="Ex: VW 15-180 TOCO BAÚ" />
                </Form.Item>
            </InputContainer>

            <Divider />

            <Typography.Title level={4}>Insira o valor</Typography.Title>
            <Typography.Text type="secondary">Preço de venda do produto anúnciado.</Typography.Text>
            
            <InputContainer>
                <Form.Item
                    name="price"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                    <InputNumber
                        placeholder="R$ 100.000"
                        min={0}
                        formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        parser={value => value.replace(/R\$\s?|(\.*)/g, '')}
                        value={0}
                        size="large"
                    />
                </Form.Item>
            </InputContainer>
        </Form>
    );
});

export default VehicleCreateMain;