export const Equipment = [
    "Baú",
    "Baú Refrigerado",
    "Carroceria",
    "Sider", 
    "Prancha", 
    "Guincho",
    "Caçamba",
    "Outro", 
    "Nenhum"
];