import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { login } from '../../domain/auth/action';
import { useDomain } from '../../utils/domain';
import Input from '../../components/Input';
import { Title } from './style';

export default () => {
  const [form] = Form.useForm();
  const { dispatch } = useDomain(state => state);
  const history = useHistory();
  const onSubmit = useCallback(async (result) => {
    try {
      message.loading({
        key: 'login',
        content: 'Carregando...',
      });
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, result);
      message.success({
        key: 'login',
        content: 'Logado com sucesso!',
      });
      
      dispatch(login(response.headers['authorization'], response.data));
      history.push('/');
    } catch (e) {
      console.log(e);
      message.error({
        key: 'login',
        duration: 3,
        content: 'Usuário e/ou senha incorretos.',
      });
    }
  }, [dispatch, history]);

  return (
    <Form form={form} name="login" onFinish={onSubmit}>
      <Title ali level={2}>Login</Title>

      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input
          placeholder="E-mail"
          size="large"
          block
        >
          <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
        </Input>
      </Form.Item>

      <Form.Item 
        name="password"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input
          type="password"
          placeholder="Senha"
          size="large"
          block
        >
          <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
        </Input>
      </Form.Item>

      <Button type="primary" htmlType="submit" size="large" block>
        Entrar
      </Button> 
    </Form>
  );
};