import StringMask from 'string-mask';

export const createMask = (Mask) => (value, prev) => {
  if (value == null) {
    return;
  }

  let normVal = value.replace(/\D/g, '');
  let normPrevVal = (prev || '').toString().replace(/\D/g, '');

  if (normVal !== normPrevVal) {
    return Mask.apply(normVal);
  }

  return value;
}

export const PhoneMask = new StringMask('(00) 90000-0000');
export const CnpjMask = new StringMask('00.000.000/0000-00');
export const IeMask = new StringMask('000.000.000.000');
export const CepMask = new StringMask('00000-000');