import React, { memo, useCallback, useState } from "react";
import { Tabs, List, Avatar } from "antd";
import Card from "../../../components/Card";
import { TabContent } from "./style";

const DashboardTop = memo(({ data }) => {
    const [curr, setCurr] = useState(1);

    const onTabSelect = useCallback((e) => {
        setCurr(e);
    }, [setCurr]);

    return (
        <Card title="Top anúncios" mode="table" marginBottom>
            <Tabs defaultActiveKey={curr} onChange={onTabSelect}>
                <Tabs.TabPane tab="Mais visualizações" key="1">
                    <TabContent>
                        <List
                            itemLayout="horizontal"
                            dataSource={data.topPageViews?.filter(x => !!x) ?? []}
                            renderItem={item => (
                                <List.Item actions={[<a href={`${window.location.origin}/caminhao/${item.u_title}`} rel="noopener noreferrer" target="_blank">Ver</a>]}>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar shape="square" size={45} src={`${process.env.REACT_APP_IMAGE_URL}/${item.images[0]}`} />
                                        }
                                        title={<a href="https://ant.design">{item.title}</a>}
                                    />
                                </List.Item>
                            )}
                        />
                    </TabContent>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Mais interesses" key="2">
                    <TabContent>
                        <List
                            itemLayout="horizontal"
                            dataSource={data.topPhoneViews?.filter(x => !!x) ?? []}
                            renderItem={item => (
                                <List.Item actions={[<a href={`${window.location.origin}/caminhao/${item.u_title}`} rel="noopener noreferrer" target="_blank">Ver</a>]}>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar shape="square" size={45} src={`${process.env.REACT_APP_IMAGE_URL}/${item.images[0]}`} />
                                        }
                                        title={<a href="https://ant.design">{item.title}</a>}
                                    />
                                </List.Item>
                            )}
                        />
                    </TabContent>
                </Tabs.TabPane>
            </Tabs>
        </Card>
    );
});

export default DashboardTop;