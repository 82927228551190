import React, { useCallback, useState, memo } from 'react';
import { Form, Input, Select, Modal, message } from 'antd';
import { api, useAxios } from '../../../utils/api';
import { useProfile, useUser, Profile } from '../../../utils/profile';
import { getAvailableProfiles } from '../util';

const UserCreate = memo(({ opened, onClose, onAdd }) => {
  const [form] = Form.useForm();
  const user = useUser();
  const isAdmin = useProfile(Profile.Admin);
  const { data: stores } = useAxios('/store', { autoFetch: isAdmin }, []);
  const [loading, setLoading] = useState(false);

  const addHandler = useCallback(async () => {
    let dto;

    try {
      dto = await form.validateFields();
    } catch (e) {
      return;
    }

    try {
      setLoading(true);
      message.loading({
        key: 'add-user',
        content: 'Adicionando...'
      });

      dto.store = dto.store || user.store._id;
      const { data } = await api.post('/user', dto);

      message.success({
        key: 'add-user',
        content: 'Usuário adicionada com sucesso',
        duration: 3
      });

      onAdd(data);
      setLoading(false);
      form.resetFields();
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'add-user',
        content: 'Erro ao adicionar usuário',
        duration: 3
      });
    }
  }, [form, onAdd, user]);

  return (
    <Modal
      title="Adicionar usuário"
      visible={opened}
      confirmLoading={loading}
      onOk={addHandler}
      onCancel={onClose}
    >
      <Form form={form} name="user-create" layout="vertical">
        <Form.Item 
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input
            placeholder="Nome"
            size="large"
          />
        </Form.Item>

        <Form.Item 
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input
            placeholder="E-mail"
            size="large"
          />
        </Form.Item>

        <Form.Item 
          label="Senha"
          name="password"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input.Password
            placeholder="Senha"
            size="large"
          />
        </Form.Item>

        {isAdmin && (
          <Form.Item 
            label="Loja"
            name="store"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Select
              showSearch={true}
              size="large"
              placeholder="Loja"
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {(stores || []).map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
            </Select>
          </Form.Item>
        )}

        <Form.Item 
          label="Acesso"
          name="profile"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch={true}
            size="large"
            placeholder="Perfil"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {getAvailableProfiles(user ? user.profile : '').map(x => <Select.Option key={x.value} value={x.value}>{x.name}</Select.Option>)}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default UserCreate;