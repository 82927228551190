import React, { useCallback, useState, memo, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, message } from 'antd';
import { ActionGroup } from '../../../components/ActionGroup';
import { api, useAxios } from '../../../utils/api';

const ModelCreate = memo(({ opened, onClose, onAdd }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { data: brands, fetch } = useAxios('/brand');

  useEffect(() => {
    if (opened) {
      fetch();
    }
  }, [opened, fetch]);

  const addHandler = useCallback(async () => {
    const body = await form.validateFields();

    try {
      setLoading(true);
      message.loading({
        key: 'add-model',
        content: 'Adicionando...'
      });

      const { data } = await api.post('/model', body);

      message.success({
        key: 'add-model',
        content: 'Modelo adicionada com sucesso',
        duration: 3
      });

      onAdd(data);
      setLoading(false);
      form.resetFields();
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'add-model',
        content: 'Erro ao adicionar modelo',
        duration: 3
      });
    }
  }, [form, onAdd]);

  return (
    <Modal
      title="Adicionar modelo"
      visible={opened}
      confirmLoading={loading}
      onOk={addHandler}
      onCancel={onClose}
    >
      <Form form={form} name="model-create" layout="vertical">

        <Form.Item 
          label="Nome"
          name="name"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input
            placeholder="Nome"
            size="large"
          />
        </Form.Item>

        <Form.Item 
          label="Marca"
          name="brand"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Digite uma marca"
            size="large"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(brands || []).map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ModelCreate;