import React from "react";
import { BrandBox, BrandImage, BrandText } from "./style";

const imageUrl = (dark) => {
    if (dark) return process.env.PUBLIC_URL + "/logo-branco.png";
    
    return process.env.PUBLIC_URL + "/logo.png";
}

const Brand = ({ logoOnly, dark }) => (
    <BrandBox>
        <BrandImage src={imageUrl(dark)} alt="Bordin Veículos" />
        {!logoOnly && <BrandText dark={dark}><strong>Bordin</strong>Veículos</BrandText>}
    </BrandBox>
);

export default Brand;