import React, { memo } from "react";
import { SelectWrapper, SelectElement, SelectPrefix, SelectLabel } from "./style";

const Select = memo(({ id, icon, placeholder, width, block, children, ...rest }) => (
    <SelectWrapper width={width} block={block}>
        <SelectPrefix>{icon}</SelectPrefix>
        <SelectElement spaced={icon != null} id={id} {...rest}>
            {children}
        </SelectElement>
        {placeholder && <SelectLabel spaced={icon != null} htmlFor={id}>
            {placeholder}
        </SelectLabel>}
    </SelectWrapper>
));

export default Select;