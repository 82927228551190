import { CepMask, CnpjMask, IeMask } from '../../../utils/format';

/**
 * Map form to store.
 *
 * @export
 * @param {*} form
 * @returns
 */
export function formToStore(form) {
    const copy = {
        ...form,
        contact: {
            ...form.contact,
        },
        address: {
            ...form.address,
        },
    };

    copy.cnpj = copy.cnpj.replace(/\D/g, '');
    copy.ie = copy.cnpj.replace(/\D/g, '');
    copy.address.code = copy.address.code.replace(/\D/g, '');

    return copy;
}

/**
 * Map store to form.
 *
 * @export
 * @param {*} store
 */
export function storeToForm(store) {
    if (!store) return {};

    const copy = {
        ...store,
        contact: {
            ...store.contact,
        },
        address: {
            ...store.address,
        },
    };

    copy.cnpj = CnpjMask.apply(copy.cnpj);
    copy.ie = IeMask.apply(copy.ie);
    copy.address.code = CepMask.apply(copy.address.code);

    return copy;
}