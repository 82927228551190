
/**
 * Auth data util.
 *
 * @export
 * @class Auth
 */
export class Auth {
  /**
   * Storage used.
   *
   * @readonly
   * @static
   * @memberof Auth
   */
  static get storage() {
    return window.localStorage || window.sessionStorage;
  }

  /**
   * Get token and user data from storage.
   *
   * @static
   * @returns
   * @memberof Auth
   */
  static getStorageData() {
    const token = this.storage.getItem('token');
    let user = this.storage.getItem('user');

    if (user != null) {
      user = JSON.parse(user);
    }

    return { token, user };
  }

  /**
   * Set token and user data in storage.
   *
   * @static
   * @param {*} token
   * @param {*} user
   * @memberof Auth
   */
  static setStorageData(token, user) {
    this.storage.setItem('token', token);
    this.storage.setItem('user', JSON.stringify(user));
  } 

  /**
   * Delete storage data.
   *
   * @static
   * @memberof Auth
   */
  static deleteStorageData() {
    this.storage.removeItem('token');
    this.storage.removeItem('user');
  }
}