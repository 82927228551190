import React, { useCallback, useState, memo } from 'react';
import { message } from 'antd';
import StoreForm from '../StoreForm';
import LazyDrawer from '../../../components/LazyDrawer';
import { api } from '../../../utils/api';

const StoreCreate = memo(({ opened, onClose, onAdd }) => {
  const [loading, setLoading] = useState(false);
  const addHandler = useCallback(async (store, form) => {
    try {
      setLoading(true);
      message.loading({
        key: 'add-store',
        content: 'Adicionando...'
      });

      const { data } = await api.post('/store', store);

      message.success({
        key: 'add-store',
        content: 'Loja adicionada com sucesso',
        duration: 3
      });

      onAdd(data);
      setLoading(false);
      form.resetFields();
    } catch (e) {
      setLoading(false);
      message.error({
        key: 'add-store',
        content: 'Erro ao adicionar loja',
        duration: 3
      });
    }
  }, [onAdd]);

  return (
    <LazyDrawer
      title="Adicionar loja"
      size="480px"
      opened={opened}
      onClose={onClose}
    >
      <StoreForm name="create-store" onCancel={onClose} onFinish={addHandler} disabled={loading} />
    </LazyDrawer>
  );
});

export default StoreCreate;