import { useState, useContext, useEffect } from "react";
import { CommonCtx } from "../domain/provider";

export function useDomain(fn = () => "") {
    const { state, dispatch } = useContext(CommonCtx);
    const [select, setSelect] = useState(fn(state));

    useEffect(() => {
        const value = fn(state);
        if (JSON.stringify(value) !== JSON.stringify(select)) {
            setSelect(value);
        }
    }, [select, state, fn, setSelect]);

    return { state: select, dispatch };
}