import { Profile, ProfileMap } from '../../utils/profile';

/**
 * Get available profiles by user profile.
 *
 * @param {*} profile User profile.
 * @returns
 */
export function getAvailableProfiles(profile) {
  if (profile === Profile.Admin) {
    return [
      { name: ProfileMap[Profile.Admin], value: Profile.Admin },
      { name: ProfileMap[Profile.StoreOwner], value: Profile.StoreOwner },
      { name: ProfileMap[Profile.StoreMember], value: Profile.StoreMember }
    ];
  } else if (profile === Profile.StoreOwner) {
    return [
      { name: ProfileMap[Profile.StoreOwner], value: Profile.StoreOwner },
      { name: ProfileMap[Profile.StoreMember], value: Profile.StoreMember }
    ]; 
  } else {
    return [];
  }
};

const profileWeightMap = {
  [Profile.Admin]: 2,
  [Profile.StoreOwner]: 1,
  [Profile.StoreMember]: 0,
};

export function compareProfile(a, b) {
  const aWeight = profileWeightMap[a];
  const bWeight = profileWeightMap[b];
  const result = aWeight - bWeight;

  if (result > 0) return 1;
  else if (result < 0) return -1;
  
  return 0;
}
