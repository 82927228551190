import * as moment from 'moment'

export function periodToQuery(period) {
    if (period === "month") {
        return {
            startIn: moment().startOf("month").format("YYYY-MM-DD"),
            endIn: moment().endOf("month").format("YYYY-MM-DD"),
        };
    } else if (period === "quarter") {
        return {
            startIn: moment().startOf("month").subtract(2, "month").format("YYYY-MM-DD"),
            endIn: moment().endOf("month").format("YYYY-MM-DD"),
        };
    }
}