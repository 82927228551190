import React, { useState, useCallback, memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { openLoading, closeLoading } from '../../domain/loading/action';
import { useAxios } from '../../utils/api';
import { useDomain } from '../../utils/domain';
import Title from '../../components/Title';
import VehicleList from './VehicleList';
import VehicleFilter from './VehicleFilter';

export default memo(() => {
  const history = useHistory();
  const { dispatch } = useDomain();
  const [search, setSearch] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const { data, loading, fetch } = useAxios('/vehicle/metric', null, []);

  const goToNewPage = useCallback(() => {
    history.push("/vehicles/new")
  }, [history]);

  const onDelete = useCallback(() => {
    fetch();
  }, [fetch]);

  const filterVehicles = useCallback((qs) => {
    fetch({
      qs,
    });
  }, [fetch]);

  const changeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, [setSearch]);

  useEffect(() => {
    setVehicles(
      data.filter(x => 
        x.title.toLowerCase().match(search.toLowerCase())
      ),
    );
  }, [data, search, setVehicles]);

  useEffect(() => {
    if (loading) {
      dispatch(openLoading('content'));
    } else {
      dispatch(closeLoading());
    }
  }, [loading, dispatch]);

  return (
    <Row justify="center">
      <Col xxl={16}>
        <Title content="Veiculos">
          <Button size="large" icon={<PlusOutlined />} onClick={goToNewPage} type="primary">Novo</Button>
        </Title>
        
        <VehicleFilter onFilterSelect={filterVehicles} onSearchChange={changeSearch} />

        <VehicleList vehicles={vehicles} onDelete={onDelete} />
      </Col>
    </Row>
  );
});