import React, { memo } from "react";
import { IconWrapper, TitleWrapper, NumberItem, NumberWrapper } from "./style";

const Statistic = memo(({ icon, title, entries }) => (
    <>
        <IconWrapper>
            {icon}
        </IconWrapper>

        <TitleWrapper>
            {title}
        </TitleWrapper>

        <NumberWrapper>
            {entries.map(([key, value]) => <NumberItem key={key}>
                {value}
                <span>{key}</span>
            </NumberItem>)}
        </NumberWrapper>
    </>
));

export default Statistic;