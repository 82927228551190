import styled, { css } from "styled-components";

export const InputWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: ${p => p.block ? "100%" : p.width || "200px"};
    height: 40px;
    margin: 12px 0;
    vertical-align: top;

    .ant-form-item-control & {
        margin-bottom: 5px;
    }
`;

export const InputPrefix = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 40px;
    left: 0;
    bottom: 0;
    z-index: 1;
`;

export const InputElement = styled.input`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 13px;
    font-weight: 300;
    border-bottom: 2px solid #DDD;
    z-index: 2;

    ${p => p.spaced && css`
        width: calc(100% - 30px);
    `}
`;

export const InputMarker = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    transform: rotateY(90deg);
    transition: transform 0.2s ease-out;
    background: ${p => p.theme.color.primary};
    z-index: 3;

    ${InputElement}:focus ~ & {
        transform: rotateY(0deg);
    }

    ${p => p.spaced && css`
        width: calc(100% - 30px);
    `}
`;

export const InputLabel = styled.label`
    position: absolute;
    top: 14px;
    left: ${p => p.spaced ? "30px" : "0"};
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    font-weight: 400;
    color: #666;
    transition: all 0.15s ease-out;

    ${InputElement}:focus ~ & {
        top: -13px;
        color: ${p => p.theme.color.primary};
    }

    ${InputElement}:not([value=""]) ~ & {
        top: -13px;
    }
`;