import React, { useCallback, memo } from 'react';
import { Table, Popconfirm, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Card from '../../../components/Card';
import Column from 'antd/lib/table/Column';
import { api } from '../../../utils/api';
import { Action } from './style';

export default memo(({ models, loading, onDelete, onEditClicked }) => {
  const deleteModel = useCallback(async (model) => {
    try {
      message.loading({
        content: 'Removendo modelo...',
        key: 'remove-model',
      });

      await api.delete(`/model/${model._id}`);

      onDelete(model);

      message.success({
        content: 'Removido com sucesso',
        key: 'remove-model',
        duration: 3,
      });
    } catch (e) {
      message.error({
        content: 'Erro ao remover loja',
        key: 'remove-model',
        duration: 3,
      });
    }
  }, [onDelete]);

  return (
    <Card mode="table">
      <Table dataSource={models} loading={loading} rowKey="_id">
        <Column title="Nome" key="name" dataIndex="name" />
        <Column title="Marca" key="brand.name" dataIndex={["brand", "name"]} />
        <Column title="" key="x" dataIndex="" render={(item) => (
          <>
            <Action icon={<EditOutlined />} onClick={() => onEditClicked(item)} />
            <Popconfirm
              title={`Deseja mesmo remover a modelo '${item.name}'?`}
              okText="Sim"
              cancelText="Não"
              onConfirm={() => deleteModel(item)}
              placement="left"
            >
              <Action icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )} />
      </Table>
    </Card>
  );
});