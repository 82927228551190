import styled from "styled-components";

export const BrandBox = styled.div`
    display: flex;
    align-items: center;
`;

export const BrandText = styled.div`
    margin-left: 15px;
    font-size: 20px;
    color: ${p => !p.dark ? "#333" : "#FFF"};
`;

export const BrandImage = styled.img`
    height: 36px;
`;