import React, { memo, useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { closeSidebar, openSidebar } from '../../domain/sidebar/action';
import { useDomain } from '../../utils/domain';
import Brand from '../Brand';
import { MenuButton, BrandHeader } from './style';

export default memo(() => {
    const route = useRouteMatch();
    const { state, dispatch } = useDomain(x => x.sidebar);

    useEffect(() => {
        dispatch(closeSidebar());
    }, [route.url, dispatch]);

    const onMenuClicked = useCallback(() => {
        if (state.open) {
            dispatch(closeSidebar());
        } else {
            dispatch(openSidebar());
        }
        
    }, [dispatch, state.open]);

    return (
        <>
            <BrandHeader>
                <Brand logoOnly />
            </BrandHeader>

            <MenuButton>
                {!state.open && <MenuOutlined onClick={onMenuClicked} />}
                {!!state.open && <CloseOutlined onClick={onMenuClicked} />}
            </MenuButton>
        </>
    )
});