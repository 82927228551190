import React, { useState, useCallback, useEffect, memo } from 'react';
import { Button, Row, Col, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import BrandList from './BrandList';
import BrandCreate from './BrandCreate';
import BrandEdit from './BrandEdit';
import { api } from '../../utils/api';

export default memo(() => {
  const [data, setData] = useState([]);
  const [creating, setCreating] = useState(false);
  const [editingBrand, setEditingBrand] = useState(null);
  const [loading, setLoading] = useState(false);

  const getBrands = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/brand');

      setData(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      message.error({
        content: 'Erro ao carregar marcas',
        key: 'load-brands',
        duration: 3
      });
    }
  }, [setLoading, setData])

  const onNewBrand = useCallback((brand) => {
    setData([...data, brand]);
    setCreating(false);
  }, [setData, setCreating, data]);

  const onDelete = useCallback((brand) => {
    setData(
      data.filter(x => x._id !== brand._id)
    );
  }, [setData, data]);

  const onBrandUpdated = useCallback((brand) => {
    setData(
      data.map(x => x._id === brand._id ? brand : x)
    );
    setEditingBrand(null);
  }, [setData, data]);

  const openEditDrawer = useCallback((brand) => {
    setEditingBrand(brand);
  }, [setEditingBrand]);

  useEffect(() => {
    getBrands();
  }, [getBrands]);

  return (
    <Row justify="center">
      <Col xxl={16}>
        <PageTitle title="Marcas" extra={
          <Button onClick={() => setCreating(true)} icon={<PlusOutlined />} type="primary">Novo</Button>
        } />

        <BrandList brands={data} loading={loading} onDelete={onDelete} onEditClicked={openEditDrawer} />

        <BrandCreate opened={creating} onClose={() => setCreating(false)} onAdd={onNewBrand} />
        <BrandEdit brand={editingBrand} opened={editingBrand != null} onClose={() => setEditingBrand(null)} onUpdate={onBrandUpdated} />
      </Col>
    </Row>
  );
});