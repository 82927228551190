import styled, { css } from "styled-components";

export const SelectWrapper = styled.div`
    position: relative;
    display: inline-block;
    width: ${p => p.block ? "100%" : p.width || "200px"};
    height: 40px;
    margin: 12px 0;
    vertical-align: top;
    border: 1px solid #DDD;
    border-radius: 4px;
`;

export const SelectPrefix = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    bottom: 0;
    z-index: 1;
`;

export const SelectElement = styled.select`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 13px;
    font-weight: 300;
    z-index: 2;

    ${p => p.spaced && css`
        width: calc(100% - 40px);
    `}
`;

export const SelectLabel = styled.label`
    position: absolute;
    top: 14px;
    left: ${p => p.spaced ? "40px" : "0"};
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    font-weight: 400;
    color: #666;
    transition: all 0.15s ease-out;

    ${SelectElement}:focus ~ & {
        top: -13px;
        color: ${p => p.theme.color.primary};
    }
`;