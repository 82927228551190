// Libs
import React, { memo, useCallback, useState } from 'react';
import { Row, Col, Steps, Button, Spin, message } from 'antd';
import { useHistory } from 'react-router-dom';
// Components
import PageTitle from '../../components/PageTitle';
import Card from "../../components/Card";
import VehicleCreateSpec from './VehicleCreateSpec';
import VehicleCreateMain from './VehicleCreateMain';
import VehicleCreateImage from './VehicleCreateImage';
import { StepContainer, ActionContainer } from './style';
// Utils
import { api } from '../../utils/api';

const VehicleCreate = memo(() => {
  const history = useHistory();
  const [state, setState] = useState({ loading: false, message: '' });
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({});
  const [imgs, setImgs] = useState([]);
  const [form, setForm] = useState(null);

  const registerHandler = useCallback((fm) => {
    setForm(fm);
  }, [setForm]);

  const finalize = useCallback(async (vehicle, images) => {
    let id = null;
    try {
      setState({ loading: true, message: "Salvando informações do veículo" });
      const { data } = await api.post(`${process.env.REACT_APP_API_URL}/vehicle`, vehicle);
      id = data._id;

      setState({ loading: true, message: "Fazendo upload das imagens" });

      const fd = new FormData();
      for (const item of images) {
        fd.append(`images`, item, item.name);
      }

      const { data: result } = await api.post(`${process.env.REACT_APP_API_URL}/vehicle/${id}/images`, fd, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      if (!result.images || !result.images.length) {
        throw new Error("Empty images");
      }

      setState({ loading: false, message: 'Upload finalizado' });
      message.success({
        content: 'Anúncio publicado',
        duration: 4,
        key: data._id,
      });

      history.push('/vehicles');
    } catch (e) {
      setState({ loading: false, message: '' });
      message.error({
        content: e.message || 'Erro ao publicar anúncio',
        duration: 5,
        key: 'new-vehicle-error',
      });

      if (id != null) {
        await api.delete(`${process.env.REACT_APP_API_URL}/vehicle/${id}`);
      }
    }
  }, [setState, history]);

  const next = useCallback(async () => {
    try {
      const val = await form.validateFields();

      if (step <= 1) {
        setValues(
          state => ({
            ...state,
            ...val,
          })
        );

        return setStep(step + 1);
      }

      await finalize(values, val);
    } catch (e) {
      console.log(e);
    }
  }, [form, values, finalize, step, setValues, setStep]);

  const back = useCallback(() => {
    if (step >= 1) {
      const vals = form.getFieldsValue();
      if (step === 1) setValues(state => ({ ...state, ...vals }));
      else if (step === 2) setImgs(vals);

      return setStep(step - 1);
    }

    history.push("/vehicles");
  }, [step, form, setStep, history]);

  return (
    <Row justify="center">
      <Col xl={20} xxl={16}>
        <PageTitle title="Novo anúncio" />
        <StepContainer>
          <Steps current={step}>
            <Steps.Step title="Principal" description="Titulo e preço" />
            <Steps.Step title="Especificações" description="Informações adicionais" />
            <Steps.Step title="Imagens" description="Finalização" />
          </Steps>
        </StepContainer>

        <Spin spinning={state.loading} tip={state.message}>
          <Card>
            {step === 0 && <VehicleCreateMain values={values} register={registerHandler} />}
            {step === 1 && <VehicleCreateSpec values={values} register={registerHandler} />}
            {step === 2 && <VehicleCreateImage values={imgs} register={registerHandler} />}
          </Card>
        </Spin>

        <ActionContainer>
          <Button size="large" type="link" disabled={state.loading} onClick={back}>
            {step === 0 ? "Cancelar" : "Voltar"}
          </Button>
          <Button onClick={next} size="large" disabled={state.loading} type="primary">
            {step === 2 ? "Finalizar" : "Continuar"}
          </Button>
        </ActionContainer>
      </Col>
    </Row>
  );
});

export default VehicleCreate;