export class FilterModel {
    static get defaultValues() {
        return {
            sort: "newest",
            status: "all",
            store: "all",
        };
    };

    static fromForm(obj) {
        let qs = {};

        if (obj.sort !== this.defaultValues.sort) {
            qs.sort = obj.sort;
        }

        if (obj.status !== this.defaultValues.status) {
            qs.status = obj.status
        }

        if (obj.store !== this.defaultValues.store) {
            qs.store = obj.store;
        }

        return qs;
    }
}