import React, { memo } from 'react';
import { AuthContainer, Content } from './style';

export default (Wrapper) => memo((props) => (
  <AuthContainer>
    <img src={process.env.PUBLIC_URL + "/logo-branco.png"} alt="logo" />
    <Content>
      <Wrapper {...props} />
    </Content>
  </AuthContainer>
));