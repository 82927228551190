import React, { useEffect, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';

import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import { useDomain } from '../../utils/domain';
import { Container, MainSection, NavbarSection, SidebarMobileOverlay, SidebarSection } from './style';
import { closeSidebar } from '../../domain/sidebar/action';

export default (Wrapper) => memo((props) => {
  const history = useHistory();
  const { state, dispatch } = useDomain(state => ({
    auth: state.auth,
    loading: state.loading,
    sidebar: state.sidebar,
  }));

  const onOverlayClicked = useCallback(() => {
    dispatch(closeSidebar());
  }, [dispatch]);

  useEffect(() => {
    if (!state.auth.token || !state.auth.user) {
      console.log("Redirecting to login...");
      history.push('/login');
    }
  }, [state, history]);

  if (!state.auth.token) {
    return (
      <div>Redirecionando para o login...</div>
    );
  }

  return (
    <Container>
      <SidebarMobileOverlay visible={state.sidebar.open} onClick={onOverlayClicked} />
      <SidebarSection open={state.sidebar.open}>
        <Sidebar />
      </SidebarSection>
      <NavbarSection>
        <Header />
      </NavbarSection>
      <MainSection>
        <Spin className="full-screen" spinning={state.loading.count > 0 && state.loading.locale === 'content'}>
          <Wrapper {...props} />
        </Spin>
      </MainSection>
    </Container>
  );
});