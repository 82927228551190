import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useAxios } from '../../utils/api';
import { useDomain } from '../../utils/domain';
import { openLoading, closeLoading } from '../../domain/loading/action';

import DashboardCards from "./DashboardAdminCards";
import DashboardChart from "./DashboardAdminChart";
import DashboardTop from "./DashboardAdminTop";

export default ({ query }) => {
  const { dispatch } = useDomain();
  const { loading, data, fetch } = useAxios(`/dashboard/system`, {
    autoFetch: false,
    qs: query
  });

  useEffect(() => {
    if (loading) {
      dispatch(openLoading('content'));
    } else {
      dispatch(closeLoading());
    }
  }, [loading, dispatch]);

  useEffect(() => {
    fetch({
      qs: query,
    });
  }, [query, fetch])

  if (loading && data == null) {
    return <p>Carregando...</p>;
  }

  return (
    <>
      <DashboardCards data={data} />

      <Row gutter={20}>
        <Col md={16} xs={24} sm={24}>
          <DashboardChart bucket={data.bucket} />
        </Col>

        <Col md={8} xs={24} sm={24}>
          <DashboardTop data={data} />
        </Col>
      </Row>
    </>
  );
}