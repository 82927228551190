import styled from 'styled-components';

export const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(${process.env.PUBLIC_URL + "/header-bg.jpg"});
  background-size: auto 100%;
  background-repeat: no-repeat;
  padding: 15px;

  img {
    position: absolute;
    top: 20px;
    margin: 0 auto;
    height: 30px;
  }
`;

export const Content = styled.div`
  width: 400px;
  background: #FFF;
  border-radius: 6px;
  padding: 50px 40px;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
`;