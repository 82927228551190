import styled from 'styled-components';
import { Button, Table as AntTable } from 'antd';

export const Action = styled(Button)`
  margin: 0 2.5px;
  float: right;
`;

export const Title = styled.p`
  line-height: 1;
  margin-bottom: 0;
  font-size: 18px;
  color: #333;
  font-weight: 500;
`;

export const Year = styled.p`
  line-height: 1;
  margin: 5px 0 10px;
  font-size: 14px;
  color: #666;
  font-weight: 300;
`;

export const Price = styled.p`
  line-height: 1;
  margin-bottom: 0;
  font-size: 20px;
  color: #666;
  font-weight: 300;
`;

export const Table = styled(AntTable)`
  thead {
    display: none;
  }

  tr td:first-child {
    width: 96px;
    padding-right: 0 !important;
  }

  tr td:last-child {
    width: 70px;
  }
  
  tr td {
    padding: 24px !important;
  }
`;