import React, { memo, useState, useCallback } from "react";
import { Modal, Select, Divider, Radio, Form } from 'antd';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { useAxios } from '../../../utils/api';
import { useProfile, Profile } from '../../../utils/profile';
import { Separator, ActionWrapper } from "./style";
import { FilterModel } from "./model";

const VehicleFilter = memo(({ onFilterSelect, onSearchChange }) => {
    const isAdmin = useProfile(Profile.Admin);
    const [form] = Form.useForm();
    const [modalOpened, setModalOpened] = useState(false);
    const { data: stores } = useAxios('/store', { autoFetch: isAdmin }, []);

    const openModal = useCallback(() => {
        setModalOpened(true);
    }, [setModalOpened]);

    const onApply = useCallback(() => {
        const values = form.getFieldsValue();
        const qs = FilterModel.fromForm(values);

        onFilterSelect(qs);
        setModalOpened(false);
    }, [form, setModalOpened, onFilterSelect]);

    const onCancel = useCallback(() => {
        setModalOpened(false);
    }, [setModalOpened]);

    return (
        <>
            <Modal
                title="Filtrar / Ordenar"
                visible={modalOpened}
                onOk={onApply}
                onCancel={onCancel}
            >
                <Form
                    labelCol={{ span: 8 }}
                    labelAlign="left"
                    wrapperCol={{ span: 16 }}
                    layout={"horizontal"}
                    form={form}
                    initialValues={FilterModel.defaultValues}
                >
                    <Form.Item label="Ordernar por" name="sort">
                        <Select placeholder="Selecione">
                            <Select.Option value="newest">Mais recentes</Select.Option>
                            <Select.Option value="desc-viewed">Mais vistos</Select.Option>
                            <Select.Option value="desc-searched">Mais procurados</Select.Option>
                            <Select.Option value="asc-viewed">Menos vistos</Select.Option>
                            <Select.Option value="asc-searched">Menos procurados</Select.Option>
                        </Select>
                    </Form.Item>
                    <Divider />
                    <Form.Item label="Status" name="status">
                        <Radio.Group>
                            <Radio value="all">Todos</Radio>
                            <Radio value="active">Ativos</Radio>
                            <Radio value="disable">Desativados</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {isAdmin && <Form.Item label="Loja" name="store">
                        <Select placeholder="Selecione">
                            <Select.Option value="all">Todas</Select.Option>
                            {stores.map(x => <Select.Option key={x._id} value={x._id}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>}
                </Form>
            </Modal>

            <ActionWrapper>
                <Button smIconOnly onClick={openModal}><FilterOutlined /> Filtrar / Ordenar</Button>
                <Separator />
                <Input width="260px" onChange={onSearchChange} placeholder="Buscar">
                    <SearchOutlined />
                </Input>
            </ActionWrapper>
        </>
    );
});

export default VehicleFilter;