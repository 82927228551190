import { useState, useCallback, useEffect } from "react";
import { useDebounce } from "./debounce";

/**
 * verify main breakpoint.
 *
 * @export
 * @returns
 */
export function useBreakpoint() {
    const [isMediumDown, setType] = useState(false);
    const verify = useCallback(() => {
        const size = window.outerWidth;
        if (size < 1024) {
            if (!isMediumDown) setType(true);
        } else {
            if (isMediumDown) setType(false);
        }
    }, [isMediumDown, setType]);

    const verifyDebounced = useDebounce(verify, 100);

    useEffect(() => {
        verifyDebounced();
        window.addEventListener("resize", verifyDebounced);

        return () => window.removeEventListener("resize", verifyDebounced);
    }, [verifyDebounced]);


    return isMediumDown;
}
