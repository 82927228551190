import React from 'react';
import ReactDOM from 'react-dom';

// Import app and sw.
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.less';
import './index.css';

// Render app.
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
