import styled from "styled-components";

export const LogoutText = styled.p`
    font-size: 14px;
    color: #666;
    margin-left: 10px;
    margin-bottom: 0;
`;

export const LogoutBox = styled.div`
    border-top: 1px solid #f1f1f1;
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px 15px;
    cursor: pointer;
    font-size: 18px;

    &:hover ${LogoutText} {
        color: #007bff;
    }

    &:hover path {
        color: #007bff;
    }
`;