import React, { memo, useState, useEffect } from 'react';
import { Typography, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Drawer, DrawerBody, DrawerOverlay, DrawerTitle } from './style';

export default memo(({ opened, size, title, onClose, children }) => {
  // Lazy render.
  const [previouslyOpened, setPreviouslyOpened] = useState(opened === true ? opened : null);

  // Update previously.
  useEffect(() => {
    if (previouslyOpened === null && opened === false) {
      return;
    }

    setPreviouslyOpened(opened);
  }, [opened, previouslyOpened, setPreviouslyOpened]);

  if (typeof previouslyOpened != 'boolean') return null;

  return (
    <>
      <DrawerOverlay opened={opened} onClick={onClose} />
      <Drawer opened={opened} size={size}>
        <DrawerBody>
          <DrawerTitle>
            <Typography.Title level={4}>{title}</Typography.Title>
            <Button icon={<CloseOutlined />} onClick={onClose} />
          </DrawerTitle>
          {opened && children}
        </DrawerBody>
      </Drawer>
    </>
  );
});