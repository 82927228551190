import axios from 'axios';
import qs from 'qs';
import { Auth } from './auth';
import { useState, useEffect, useCallback } from 'react';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Authorization': Auth.getStorageData().token,
  }
});

api.interceptors.response.use(res => res, err => {
  if (err.response.status === 401 && !window.location.href.match("/login")) {
    window.location.href = "/login";
  }

  return Promise.reject(err);
});

/**
 * Axios custom hook.
 *
 * @export
 * @param {*} path Request path.
 * @param {*} config Axios request config.
 * @param {*} [defaultValue=null] Default value.
 * @returns
 */
export function useAxios(path, config = {}, defaultValue = null, transformValue = d => d) {
  const [fetched, setFetched] = useState(config && typeof config.autoFetch != "undefined" ? !config.autoFetch : false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(defaultValue);

  const fetch = useCallback((extra = {}) => {
    let queryString = "";
    const axiosConfig = {
      ...config,
      ...extra,
    };

    if (axiosConfig.qs != null) {
      queryString = "?" + qs.stringify(axiosConfig.qs);
    }

    setLoading(true);
    setFetched(true);
    api(path + queryString, config)
      .then(response => {
        setData(transformValue(response.data));
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })
  // eslint-disable-next-line
  }, [setFetched, setData, setLoading]);

  useEffect(() => {
    if (fetched) {
      return;
    }

    fetch();
  }, [fetched, fetch]);

  return { data, error, loading, fetch };
}