import React, { memo } from "react";
import { InputWrapper, InputElement, InputPrefix, InputMarker, InputLabel } from "./style";

const Input = memo(({ id, placeholder, width, block, children, ...rest }) => (
    <InputWrapper width={width} block={block}>
        <InputPrefix>{children}</InputPrefix>
        <InputElement spaced={children != null} id={id} {...rest} />
        <InputMarker spaced={children != null}></InputMarker>
        {placeholder && <InputLabel spaced={children != null} htmlFor={id}>
            {placeholder}
        </InputLabel>}
    </InputWrapper>
));

export default Input;